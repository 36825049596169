const enPatientsTranslations = {
  DIAGNOSIS_CODES_EXPLANATION: `General History and Primary Observation are based on ICD-10-CM codes. 
    Pregnancy History and Cycle observations are free text. 
    If pregnancy or cycle observations should be considered primary, or overall, enter it as an ICD-10 Code as well.`,
  NUMBER: 'No',
  ID: 'ID',
  TLID: 'TLID',
  TIME_CANNOT_BE_IN_THE_FUTURE: 'Time cannot be in the future',
  MATURITY: 'Maturity',
  CULTURE_DISH_ID: 'Culture Dish ID',
  GROUP_CULTURE: 'Group Culture',
  EMBRYO_CULTURE_DROP_WELL: 'Embryo Culture Drop/Well',
  LABRATORY_PROTOCOLS: 'Labratory Protocols',
  BIOPSY_TECH: 'Biopsy Tech',
  DAY1: 'Day 1',
  DAY2: 'Day 2',
  DAY3: 'Day 3',
  DAY4: 'Day 4',
  DAY5: 'Day 5',
  DAY6: 'Day 6',
  DAY7: 'Day 7',
  AI_SCORE: 'AI Score',
  FATE: 'Fate',
  PGT_TUBE_ID: 'PGT Tube ID',
  PGT_TISSUE_TYPE: 'PGT Tissue Type',
  FREEZE_DATE: 'Freeze Date',
  GENETIC_STATUS: 'Genetic Status',
  INSEMINATION_METHOD: 'Insemination Method',
  INSEMINATION_TIME: 'Insemination Time',
  ADD_ONS: 'Add Ons',
  BIOPSY_WITNESS: 'Biopsy Witness',
  BIOPSY_TIME: 'Biopsy Time',
  TUBING_TECH: 'Tubing tech',
  TUBING_WITNESS: 'Tubing Witness',
  TUBING_TIME: 'Tubing Time',
  LOCATION_CANISTER: 'Location/Canister',
  BEACON: 'Beacon',
  POSITION: 'Position',
  STRAW: 'Straw',
  DISH_NUMBER: 'Dish/Slide',
  INSTRUMENT: 'Instrument',
  WELL_NUMBER: 'Well',
  EGG_ACTIVATION: 'Egg Activation',
  ASSISTED_HATCHING: 'Assisted Hatching',
  POLAR_BODY_BIOPSY: 'Polar body Biopsy',
  OTHER: 'Other',
  ADD_CHIPS_CONTAINER_TITLE: 'Add',
  LOGO_SIGN_TITLE: 'Embie Clinic Dashboard',
  ADD_NEW_PATIENT: 'Add New Patient',
  PROVIDER_REQUIRED: 'Please choose provider',
  DOCTOR_LABEL: 'CHOOSE PRIMARY PROVIDER',
  DOCTOR_DEFAULT_OPTION: 'Please choose a Primary Provider',
  ABOUT_THE_PATIENT: 'About the Patient',
  PATIENT_HAS_PARTNER: 'Partner',
  EMAIL_REQUIRED: 'Please enter a valid email',
  EMAIL_PLACEHOLDER: 'Email',
  PHONE_NUMBER_REQUIRED: 'Please enter a phone number',
  PHONE_NUMBER_LABEL: 'PHONE NUMBER',
  PHONE_NUMBER_PLACEHOLDER: '888 888 88888',
  ADDRESS_REQUIRED: 'Please enter an address',
  ADDRESS_PLACEHOLDER: 'Street Address and Number',
  CITY_REQUIRED: 'Please enter a city',
  CITY_LABEL: 'CITY',
  CITY_PLACEHOLDER: 'City',
  STATE_REQUIRED: 'Please enter a state',
  STATE_LABEL: 'STATE',
  STATE_PLACEHOLDER: 'State',
  ZIP_REQUIRED: 'Please enter a zip',
  ZIP_LABEL: 'ZIP',
  ZIP_PLACEHOLDER: 'Zip',
  COUNTRY_REQUIRED: 'Please enter a country',
  COUNTRY_LABEL: 'COUNTRY',
  COUNTRY_PLACEHOLDER: 'Country',
  DATE_OF_BIRTH_REQUIRED: 'Please choose a date',
  ID_REQUIRED: 'Please enter an id',
  SSN_LABEL: 'SSN',
  ETHNICITY_REQUIRED: 'Please choose race',
  ETHNICITY_LABEL: 'RACE',
  ETHNICITY_DEFAULT_OPTION: 'Please choose race',
  GENDER_REQUIRED: 'Please choose a gender',
  GENDER_LABEL: 'GENDER',
  GENDER_DEFAULT_OPTION: 'Please choose a gender',
  MARITAL_STATUS_REQUIRED: 'Please choose a marital status',
  MARITAL_STATUS_LABEL: 'MARITAL STATUS',
  MARITAL_STATUS_DEFAULT_OPTION: 'Please choose a marital status',
  NICK_NAME_LABEL: 'NICK NAME',
  HAS_DISABILITY_LABEL: 'Does the patient have a disability?',
  LANGUAGE_LABEL: 'LANGUAGE',
  LANGUAGE_DEFAULT_VALUE: 'English',
  LAST_NAME_AT_BIRTH_LABEL: 'LAST NAME AT BIRTH',
  TREATMENT_REASON_REQUIRED: 'Please choose a reason',
  TREATMENT_REASON_LABEL: 'MAIN REASON FOR TREATMENT',
  TREATMENT_REASON_DEFAULT_OPTION: 'Please choose a reason',
  ABOUT_THE_PARTNER: 'About the Partner',
  ADD_PATIENT_CTA: 'Add Patient',
  ETHNICITY1_ENUM: 'Ethnicity1',
  ETHNICITY2_ENUM: 'Ethnicity2',
  MARITAL_STATUS_SINGLE_ENUM: 'Single',
  MARITAL_STATUS_MARRIED_ENUM: 'Married',
  GENDER_MALE_ENUM: 'Male',
  GENDER_FEMALE_ENUM: 'Female',
  CONFIRM_EMAIL_TITLE: 'has been added to the clinic.',
  CONFIRM_EMAIL_MSG:
    "Please confirm with the patient that they've received a confirmation email where they can download the patient app.",
  CONFIRM_EMAIL_CONFIRM_BTN: 'Confirm',
  CONFIRM_EMAIL_RESEND_BTN: 'Resend',
  PATIENT_NAME_REQUIRED: 'Please enter a name',
  FIRST_NAME_LABEL: 'FIRST NAME',
  FIRST_NAME_PLACEHOLDER: 'First Name',
  LAST_NAME_LABEL: 'LAST NAME',
  LAST_NAME_PLACEHOLDER: 'Last Name',
  PATIENT_NAME_LABEL: 'PATIENT NAME',
  PATIENT_NAME_PLACEHOLDER: 'Patient name here',
  CYCLE_DAY_1_REQUIRED: 'Please choose a date',
  CYCLE_DAY_1_LABEL: 'Cycle day 1',
  MEDICATION_NAME_REQUIRED: 'Please choose a medication',
  MEDICATION_NAME_LABEL: 'MEDICATION NAME',
  MEDICATION_NAME_DEFAULT_OPTION: 'Please choose a medication',
  ORDERS_TITLE: 'ORDERS',
  APPOINTMENT_TIME_REQUIRED: 'Please choose a time',
  APPOINTMENT_DATE_REQUIRED: 'Please choose a date',
  APPOINTMENT_TYPE_REQUIRED: 'Please choose a type',
  APPOINTMENT_TYPE_LABEL: 'APPOINTMENT TYPE',
  APPOINTMENT_TYPE_DEFAULT_OPTION: 'Please choose an appointment type',
  DELIVERY_DATE_REQUIRED: 'Please choose a date',
  DELIVERY_DATE_LABEL: 'DELIVERY DATE',
  DELIVERY_TO_LABEL: 'TIME',
  DOSAGE_REQUIRED: 'Please enter a dosage',
  DOSAGE_VALIDATION_MSG: 'Dosage must be non-negative',
  INPUT_VALIDATION_MSG: 'Please enter a value',
  DOSAGE_LABEL: 'DOSAGE',
  UNIT_REQUIRED: 'Please enter a unit',
  UNIT_VALIDATION_MSG: 'Unit must be non-negative',
  UNIT_DEFAULT_OPTION: 'Unit',
  UNIT_LABEL: 'UNIT',
  QUANTITY_REQUIRED: 'Please enter a quantity',
  QUANTITY_LABEL: 'QUANTITY',
  REFILL_REQUIRED: 'Please enter a refill',
  REFILL_LABEL: 'REFILL',
  ADD_ANOTHER_APPOINTMENT: 'Add another appointment',
  CONFIRM_SCHEDULE: 'Confirm Schedule',
  ADD_MEDICATION: 'Add Medication',
  ADD_ANOTHER_RESULT: 'Add another result',
  CONFIRM_PRESCRIPTION: 'Confirm & Send Rx',
  CONFIRM_PRESCRIPTION_DIALOG_HEADER: 'Cycle Prescriptions',
  START_NEW_DIALOG_HEADER: 'Start New Cycle',
  CYCLE_SCHEDULE_DIALOG_HEADER: 'Cycle Schedule & Orders',
  BASELINE_SCAN_LABEL: 'Baseline Scan',
  MONITORING_LABEL: 'Monitoring',
  EGG_RETRIEVAL_LABEL: 'Egg Retrieval',
  OTHER_PHYSICAL_LIST_LABEL: 'Or other physical list',
  ESTROGEN: 'Estrogen',
  TSH_LABEL: 'TSH',
  FSH: 'FSH',
  LH_LABEL: 'LH',
  TESTOSTERONE_LABEL: 'Testosterone',
  FOLLICULAR_SCAN: 'Follicular Scan',
  CYCLE_SUCCESS_DIALOG_HEADER: 'New cycle was successfully added!',
  CYCLE_SUCCESS_INFO_MSG_1: 'Embie sent the Patient and Partner consent forms',
  CYCLE_SUCCESS_INFO_MSG_2:
    'You scheduled the cycle appointments and the orders for each day.',
  CYCLE_SUCCESS_INFO_MSG_3:
    'You confirmed the medication protocol. We sent it to the pharmacy.',
  CYCLE_SUCCESS_INFO_MSG_4:
    'The patient has everything they need to get started via the patient app',
  CYCLE_SUCCESS_DIALOG_SUBTITLE: 'And your care plan was confirmed',
  PATIENT_LIST_HEADER: 'Patient List',
  PATIENT_LIST_NAME_COL: 'Name',
  ADD_NEW_PATIENT_CTA: 'Add new patient',
  BCP: 'BCP',
  GONAL_F: 'Gonal-F',
  MENAPUR: 'Menapur',
  CETROTIDE: 'Cetrotide',
  OVITRELL: 'Ovitrell',
  LUPRON: 'Lupron',
  IU: 'IU',
  DOWN_REGULATION: 'Down Regulation',
  STIMULATION: 'Stimulation',
  AGONIST: 'Agonist',
  TRIGGER: 'Trigger',
  DAILY: 'Daily',
  EVERY_OTHER_DAY: 'Every Other Day',
  EVERY_THREE_DAYS: 'Every 3 Days',
  MEDICATION_PROTOCOL_HEADER: 'Medication Protocol',
  MEDICATION_TYPE_DEFAULT: 'Medication Type',
  PROTOCOL_PHASE_REQUIRED: 'Please choose a protocol phase',
  PROTOCOL_PHASE: 'PROTOCOL PHASE',
  PROTOCOL_PHASE_DEFAULT: 'Protocol Phase',
  START_DATE_REQUIRED: 'Please choose a start date',
  END_DATE_REQUIRED: 'Please choose an end date',
  START_DATE_LABEL: 'START DATE',
  END_DATE_LABEL: 'END DATE',
  TIMES_PER_DAY_REQUIRED: 'Please choose times per day',
  TIMES_PER_DAY_LABEL: 'TIMES PER DAY',
  TIMES_PER_DAY_PLACEHOLDER: 'Times Per Day',
  HOW_OFTEN_REQUIRED: 'Please choose how often',
  HOW_OFTEN_LABEL: 'HOW OFTEN',
  HOW_OFTEN_PLACEHOLDER: 'How Often',
  CONFIRM_PROTOCOL: 'Confirm Protocol',
  KPI_PAGE_TITLE: 'KPI Dashboard',
  KPI_LAST_THIRTY_DAYS: 'Last 30 Days',
  KPI_PATIENT_STATUS: 'Patient Status',
  KPI_FERTILIZATION_RATE: 'Fertilization Rate',
  KPI_CYCLE_STATUS: 'Cycle Status',
  KPI_PREGNANCY_RATE: 'Pregnancy Rate',
  INBOX: 'Inbox',
  TASKS: 'Tasks',
  PATIENT_NOTES: 'Patient Notes',
  PRIORITY: 'Priority',
  COMMENTS: 'Comments',
  CATEGORY: 'Category',
  OPEN_TASKS: 'Open Tasks',
  CLOSED_TASKS: 'Closed Tasks',
  TODAY_TASKS: 'Today',
  ADDITIONAL_TASKS: 'Additional Outstanding Tasks',
  STANDARD_VIEW: 'Standard view',
  LAB_VIEW: 'Lab view',
  MESSAGES: 'Messages',
  NEW_TASK: 'New Task',
  SHARE: 'Share',
  ASSIGN_TO: 'Assign To',
  ARCHIVE: 'Archive',
  AGE: 'Age',
  DOB: 'DOB',
  SEX: 'Sex',
  BMI: 'BMI',
  TTC: 'TTC',
  AMH: 'AMH',
  YEARS: 'YEARS',
  MONTHS: 'MONTHS',
  CYCLE: 'Cycle',
  CYCLE_ALL_CAPS: 'CYCLE',
  STATUS: 'Status',
  DIAGNOSIS: 'DIAGNOSIS',
  RACE: 'Race',
  PATIENT_ID: 'Patient ID',
  THE_BASICS: 'THE BASICS',
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  TREATMENT_OVERVIEW: 'TREATMENT OVERVIEW',
  START_DATE: 'Start Date',
  TREATMENT: 'Treatment',
  NONE: 'None',
  PROTOCOL: 'Protocol',
  EGG_SOURCE: 'Egg Source',
  SPERM_SOURCE: 'Sperm Source',
  SPERM_PREP: 'Sperm Prep',
  FLAGS: 'Flags',
  SPERM_CATEGORY: 'Sperm Category',
  SURROGACY: 'Surrogacy',
  PATIENT_INBOX: 'Patient Inbox',
  NEW_MESSAGE: 'New Message',
  TEAM_INBOX: 'Team Inbox',
  ASSIGNED_TO_ME: 'Assigned To Me',
  NOT_ASSIGNED: 'Not Assigned',
  SCHEDULE: 'Schedule',
  INTERNAL: 'Internal',
  PATIENT: 'Patient',
  PATIENT_OVERVIEW: 'Patient Overview',
  PATIENT_CHAT: 'Patient Chat',
  INTERNAL_CHAT: 'Internal Chat',
  TELEHEALTH: 'Telehealth',
  INTENDED_TREATMENT: 'Intended Treatment',
  HORMONAL_PANEL: 'HORMONAL PANEL',
  PROLACTIN: 'Prolactin',
  ESTRADIOL_PG_ML: 'Estradiol (pg/ml)',
  COUNT: 'Count',
  MILLION: 'MILLION',
  VOLUME: 'Volume',
  VOLUME_VALIDATION_MSG: 'Volume must be non-negative',
  ML: 'ml',
  MOTILITY: 'Motility',
  PRECENT: 'PRECENT',
  MORPH: 'Morph',
  MORPHOLOGY_DEFECTS: 'Morphology Defects',
  HEAD: 'Head',
  TAIL: 'Tail',
  FULL_MEDICAL_HISTORY_CTA: 'See Full Medical History',
  ADD_CYCLE: 'Add Cycle',
  IVF_ICSI: 'IVF/ICSI',
  IUI: 'IUI',
  IVF_ICSI_FRESH: 'IVF/ICSI + Fresh Transfer',
  FET: 'FET',
  MEDICATED_CYCLE: 'Medicated Cycle',
  ERA: 'ERA',
  MOCK_TRANSFER: 'Mock Transfer',
  ANTAGONIST: 'Antagonist',
  LONG_ANTAGONIST: 'Long Agonist',
  MILD_MINI_IVF: 'Mild/Mini IVF',
  NATURAL: 'Natural',
  MODIFIED_NATURAL: 'Modified Natural',
  GONAL_150: 'Antagonist Gonal 150',
  LONG_AGONIST_30_DAY: 'Long Agonist Lupron 30 Day',
  THIS_CLINIC: 'At this clinic',
  PARTNER_CLINIC: 'Partner clinic',
  OWN: 'Own',
  DONOR: 'Donor', // Used for multiple - do not delete
  PARTNER_FRESH: 'Partner/Fresh',
  PARTNER_FROZEN: 'Partner/Frozen',
  NO: 'No',
  YES: 'Yes',
  PLEASE_SELECT_ONE_TREATMENT: 'Please select at least one treatment type',
  TREATMENT_TYPE: 'TREATMENT TYPE',
  PLEASE_CHOOSE_TREATMENT: 'Please choose a treatment type',
  PLEASE_SELECT_DATE: 'Please select at least one treatment type',
  PLEASE_SELECT_ONE_PROTOCOL: 'Please select at least one protocol type',
  PROTOCOL_TYPE: 'PROTOCOL TYPE',
  PLEASE_CHOOSE_PROTOCOL: 'Please choose a protocol type',
  PLEASE_SELECT_ONE_TEMPLATE: 'Please select at least one protocol type',
  PROTOCOL_TEMPLATE: 'PROTOCOL TEMPLATE',
  PLEASE_CHOOSE_TEMPLATE: 'Please choose a template',
  PLEASE_SELECT_LOCATION: 'Please select a location',
  LOCATION_OF_TREATMENT: 'LOCATION OF TREATMENT',
  PLEASE_CHOOSE_LOCATION: 'Please choose location',
  PLEASE_SELECT_EGG_SOURCE: 'Please select an egg source',
  PLEASE_CHOOSE_EGG_SOURCE: 'Please choose an egg source',
  PLEASE_SELECT_SPERM_SOURCE: 'Please select an sperm source',
  PLEASE_CHOOSE_SPERM_SOURCE: 'Please choose an sperm source',
  YES_OR_NO: 'Please choose yes or no',
  PLEASE_CHOOSE_OPTION: 'Please choose an option',
  EGG_OPTIONS: 'EGG OPTIONS',
  SPERM_OPTIONS: 'SPERM OPTIONS',
  UTERUS_OPTIONS: 'UTERUS OPTIONS',
  GENETIC_OPTIONS: 'GENETIC OPTIONS',
  ADDITIONAL_NOTES: 'Additional Notes',
  CRYO_TRANSPORT: 'CRYO TRANSPORT',
  PLEASE_CHOOSE_CYRO: 'Please choose cryo transport',
  TREATMENT_PLAN_USAGE: 'TREATMENT PLAN USAGE',
  PLEASE_ENTER_NUMBER: 'Please enter a number',
  TREATMENT_NUMBER: 'TREATMENT NUMBER',
  UPCOMING: 'Upcoming',
  PREVIOUS: 'Previous',
  CHECKLIST: 'Checklist',
  AVATAR: 'Avatar',
  DATE: 'Date',
  DETAILS: 'Details',
  LAUNCH_MEETING: 'Launch Meeting',
  LIST_ITEM: 'LIST ITEM',
  TEMPLATE_LIST: 'Select template from the list',
  DUE_DATE: 'Due Date',
  ASSIGNED_TO: 'Assigned To',
  CRYO: 'Cryo',
  TRANSFERRED: 'Transferred',
  DISCARDED: 'Discarded',
  EUPLOID: 'Euploid',
  ANEUPLOID: 'Aneuplod',
  MOSIAC: 'MOSIAC',
  EGGS: 'EGGS',
  SPERM: 'SPERM',
  GAMETE_REPORT: 'GAMETE REPORT',
  PATIENT_MEDICAL_HISTORY: 'Patient Medical History',
  PARTNER_MEDICAL_HISTORY: 'Partner Medical History',
  PREVIOUS_OHSS: 'Previous OHSS',
  LOW_FERTILIZATION: 'Low Fertilization',
  ALLERGIC_TO_LATEX: 'Allergic to Latex',
  INBOX_PAGE_TITLE: 'Embie Clinic | Inbox',
  CALENDAR_PAGE_HEADER_TITLE: 'Embie Clinic | Calendar',
  PATIENT_LIST_PAGE_TITLE: 'Embie Clinic | Patient List',
  DASHBOARD_PAGE_TITLE: 'Embie Clinic | Dashboard',
  EDIT_PATIENT_PAGE_TITLE: 'Embie Clinic | Edit Patient',
  PATIENT_OVERVIEW_PAGE_TITLE: 'Embie Clinic | Patient Overview',
  PATIENT_KPI_PAGE_TITLE: "Embie Clinic | Patient KPI's",
  CONFIRM_EMAIL_PAGE_TITLE: 'Embie Clinic | Confirm Email',
  PATIENT_TASKS_PAGE_TITLE: 'Embie Clinic | Tasks',
  PATIENT_FEED_PAGE_TITLE: 'Embie Clinic | Feed',
  CLINIC_FEED_PAGE_TITLE: 'Embie Clinic | Clinic Feed',
  PATIENT_TREATMENT_CHART_PAGE_TITLE: 'Embie Clinic | Treatment',
  PATIENT_MEDICAL_HISTORY_PAGE_TITLE: 'Embie Clinic | Medical History',
  PATIENT_FORMS_PAGE_TITLE: 'Embie Clinic | Forms',
  CLINIC_SETTINGS_PAGE_TITLE: 'Embie Clinic | Clinic Settings',
  EMPTY_PAGE_TITLE: 'Embie Clinic | Coming Soon',
  SIGN_IN_PAGE_TITLE: 'Embie Clinic | Sign in',
  BETA: 'Beta',
  OUTCOME: 'Outcome',
  NOTES: 'Notes',
  ADD_NOTE: 'Add Note',
  VIEW_NOTE: 'View Note',
  MEDICATED: 'Medicated',
  LONG_AGONIST: 'Long agonist',
  TREATMENT_HISTORY: 'TREATMENT HISTORY',
  DATE_MUST_BE_PREVIOUS: "Please choose a date that is prior to today's date",
  ADD_PREVIOUS_CYCLE: 'Add previous cycle',
  DOCUMENT: 'Document',
  TASK: 'Task',
  NEW_CYCLE: 'New Cycle',
  ORDER: 'Order',
  EXTERNAL_ORDER: 'External order',
  PATIENT_CHECKLIST: 'Patient Tasks',
  PARTNER_CHECKLIST: 'Partner Tasks',
  MARRIED: 'Married',
  SINGLE: 'Single',
  DOMESTIC_PARTNERSHIP: 'Domestic Partnership',
  PARENTAL_PARTNERSHIP: 'Parental Partnership',
  WHITE_EUROPEAN: 'White/European',
  MALE: 'Male',
  FEMALE: 'Female',
  VITAMIN_D_25_HYDROXY: 'Vitamin D (25-hydroxy)',
  VITAMIN_D_1: 'Vitamin D (1)',
  ANDROSTENEDIONE: 'Androstenedione',
  PROGESTERONE_17_OH: '17-OH-progesterone',
  CORTISOL: 'Cortisol',
  SHBG: 'SHBG (Sex Hormone Binding Globulin)',
  FAI_DHEA: 'Free Androgen Index (FAI) DHEA-S',
  TPO: 'Thyroid peroxidase antibodies (TPO)',
  PMOL_L: 'pmol/L',
  DATE_TAKEN_REQUIRED: 'Please enter date taken',
  DATE_TAKEN_LABEL: 'DATE TAKEN',
  TEST_NAME_REQUIRED: 'Please enter test name',
  TEST_NAME_LABEL: 'TEST NAME',
  TEST_RESULT_REQUIRED: 'Please enter test result',
  TEST_RESULT_LABEL: 'RESULT',
  MEASUREMENT_TYPE_REQUIRED: 'Please enter measurement type',
  MEASUREMENT_TYPE_LABEL: 'MEASUREMENT TYPE',
  LAB_RESULT_COMMENT_REQUIRED: 'Please enter a comment',
  LAB_RESULT_COMMENT_LABEL: 'COMMENT',
  LAB_RESULT_UNIT_TYPE_LABEL: 'UNIT TYPE',
  LAB_RESULT_MIN_VALUE_LABEL: 'MIN VALUE',
  LAB_RESULT_MAX_VALUE_LABEL: 'MAX VALUE',
  LAB_RESULT_UNIT_TYPE_PLACEHOLDER: 'Unit Type',
  LAB_RESULT_MIN_VALUE_PLACEHOLDER: 'Min Value',
  LAB_RESULT_MAX_VALUE_PLACEHOLDER: 'Max Value',
  LAB_RESULT_COMMENT_PLACEHOLDER: 'Please enter a comment',
  IS_FLAGGED_LABEL: 'Flag',
  SUBMIT: 'Submit',
  NEXT: 'Next',
  ESTRADIOL_HISTORY: 'ESTRADIOL HISTORY',
  CREATE_EGGS_NOTES_ERROR_TOAST_TITLE: 'Error adding note',
  TRY_AGAIN_LATER: 'Please try again later or contact support for help.',
  HISTORY: 'History',
  BETA_HCG: 'BETA hcG',
  ONGOING: 'Ongoing',
  DONE: 'Done',
  SELECT: 'Select',
  SECOND_BETA: '2nd BETA',
  PREGNANCY: 'Pregnancy',
  ADD_LABS: 'Add Labs',
  TEST_NAME: 'Test name',
  DATE_TAKEN: 'DATE TAKEN',
  ENTER_TEST_NAME: 'Enter test name',
  ENTER_RESULT: 'Enter result',
  CHOOSE_MEASUREMENT_TYPE: 'Choose measurement type',
  MEASURMENT_TYPE_DEFAULT_OPTION: 'Please choose measurment type',
  ESTRADIOL: 'Estradiol',
  PROGESTERONE: 'Progesterone',
  LH: 'LH',
  CURRENT_TREATMENT: 'CURRENT TREATMENT',
  CHART: 'CHART',
  ADD_NEW_REPORT: 'Add New Report',
  CHOOSE_METRIC_SYSTEM: 'CHOOSE METRIC SYSTEM',
  CM_KG: 'CM/KG',
  IN_LB: 'IN/LB',
  FT_LB: 'FT/LB',
  PLEASE_ENTER_A_HEIGHT: 'Please enter a height',
  PLEASE_ENTER_A_WEIGHT: 'Please enter a weight',
  HEIGHT: 'Height',
  HEIGHT_FT: 'Height (ft)',
  HEIGHT_IN: 'Height (in)',
  WEIGHT: 'Weight',
  HOW_LONG_HAVE_YOU_BEEN_TTC: 'HOW LONG HAVE YOU BEEN TTC?',
  AVERAGE_MENSTRUAL_CYCLE_LENGTH: 'AVERAGE MENSTRUAL CYCLE LENGTH',
  PHONE: 'Phone',
  PARTNER_PHONE: 'Partner Phone',
  BUSY: 'BUSY',
  NO_ROOM: 'No Room',
  GET_PATIENT_CHECKLISTS_ERROR_TOAST_TITLE: 'Error fetching patient tasks',
  GET_PARTNER_CHECKLISTS_ERROR_TOAST_TITLE: 'Error fetching partner tasks',
  GET_PATIENT_INBOX_DATA_ERROR_TOAST_TITLE: 'Error fetching patient inbox data',
  UPDATE_PATIENT_CURRENT_TREATMENT_NOTES_ERROR_TOAST_TITLE:
    'Error updating patient current treatment notes',
  PAST_VISITS: 'Past visits',
  NO_PAST_VISITS: 'No past visits found',
  FETCHING_PAST_VISITS_ERROR_TITLE: 'Error fetching patient past visits data',
  FETCHING_PAST_VISITS_ERROR_CHILDREN:
    'Please try again later or contact support for help.',
  'Embryo Transfer': 'Embryo Transfer',
  LAB_REVIEW: 'LAB REVIEW',
  TEST: 'Test',
  PARTNER_LAB_REVIEW: 'PARTNER LAB REVIEW',
  EDIT_LAB_RESULT: 'Edit Lab Results',
  UPDATE_PATIENT_PARTNER_LAB_REVIEW_TOAST_TITLE:
    'Error fetching patient partner lab review data',
  GET_REPORT_ERROR_TOAST_TITLE: 'Error fetching report',
  SEMEN_ANALYSIS_REPORT: 'Semen Analysis Report',
  DATE_PRODUCED: 'Date',
  TIME_RECEIVED: 'Time Received',
  TIME_PRODUCED: 'Time Produced',
  CLINIC: 'Clinic',
  DATE_OF_ANALYSIS: 'Date of analysis',
  EJAC: 'ejac',
  DAYS_OF_ABSTINENCE: 'Days of Abstinence',
  DAYS_OF_ABSTINENCE_VALIDATION_MSG: 'Days of Abstinence must be non-negative',
  APPEARANCE: 'Appearance',
  GREY_OPALESCENT: 'grey opalescent',
  RED_BROWN: 'red brown',
  YELLOW: 'yellow',
  CLEAR: 'clear',
  CRYSTALS: 'crystal',
  GEL: 'gel',
  VISCOSITY: 'Viscosity',
  VISCOUS: 'Viscous (>2cm thread)',
  NORMAL: 'Normal',
  PH: 'pH',
  LIQUEFACTION: 'Liquefaction',
  PARTIAL: 'Partial',
  NO_LIQUEFACTION: 'No Liquefaction',
  TIME_OF_ANALYSIS: 'Time of analysis',
  NUMBER_OF_WASHES: 'Number of Washes',
  PERFORMED_BY: 'Performed by',
  CONCENTRATION: 'Concentration',
  CONCENTRATION_VALIDATION_MSG: 'Concentration must be non-negative',
  'M/ML': 'M/ML',
  PERCENT: 'PERCENT',
  KEY_RESULTS: 'KEY RESULTS',
  COMMENT: 'COMMENT',
  MOVEMENT: 'MOVEMENT',
  CREATE_OUTCOME_REPORT_ERROR_TOAST_TITLE: 'Error creating outcome report',
  CREATE_SEMEN_REPORT_ERROR_TOAST_TITLE: 'Error creating semen analysis report',
  CHOOSE_PREVIOUS_CYCLE: 'Choose Previous Cycle',
  LEFT_FOLLICLES: 'Left Follicles',
  RIGHT_FOLLICLES: 'Right Follicles',
  ENDOMETRIUM: 'ENDOMETRIUM',
  OBSERVATIONS: 'OBSERVATIONS',
  LEFT_AFC: 'LEFT AFC',
  RIGHT_AFC: 'RIGHT AFC',
  FOLLICLES_TREATMENT_GRID_HEADER: 'FOLLICLES (L/R)',
  ENDOMETRIUM_TREATMENT_GRID_HEADER: 'ENDOMETRIUM',
  GONAL_F_TREATMENT_GRID_HEADER: 'GONAL-F',
  PROGESTERONE_SUP_400_TREATMENT_GRID_HEADER: 'PROGESTERONE SUP 400',
  CETROTIDE_TREATMENT_GRID_HEADER: 'CETROTIDE',
  OVITRELL_TREATMENT_GRID_HEADER: 'OVITRELL',
  NOTES_TREATMENT_GRID_HEADER: 'NOTES',
  E2_TREATMENT_GRID_HEADER: 'E2',
  LH_TREATMENT_GRID_HEADER: 'LH',
  lh_e2_treatment_grid_sub_header: 'unit/ml',
  Polyps: 'Polyps',
  leiomyomas: 'leiomyomas',
  adenomyosis: 'adenomyosis',
  unicornuate: 'unicornuate',
  septate: 'septate',
  BICORNUATE_UTERI: 'bicornuate uteri',
  SUBMUCOUS_MYOMAS: 'submucous myomas',
  synechiae: 'synechiae',
  endometriosis: 'endometriosis',
  Cyst: 'Cyst',
  Varicocele: 'Varicocele',
  endometrioma: 'endometrioma',
  TRILAMINAR_ENDOMETRIAL: 'Trilaminar Endometrial',
  FLUID_IN_UTERINE_CAVITY: 'Fluid in Uterine Cavity',
  OUTCOME_REPORT: 'Outcome Report',
  CYCLE_STATUS: 'Cycle status',
  CYCLE_OUTCOME: 'Cycle outcome',
  PREGNANCY_STATUS: 'Pregnancy status',
  DATE_OF_BIRTH: 'Date of birth',
  NUMBER_OF_BABIES: 'Number of babies',
  BABY_SEX: 'Baby sex',
  BABY_WEIGHT: 'Baby weight',
  COMPLETE: 'Complete',
  CHOOSE_PATIENT: 'Choose a patient',
  CANCELED_LOW_RESPONSE: 'Canceled - Low Response',
  CANCELED_LINING: 'Canceled - Lining',
  CANCELED_FLUID: 'Canceled - Fluid',
  CANCELED_HIGH_RISK: 'Canceled - High Risk',
  CANCELED_OTHER: 'Canceled Other',
  TBD: 'TBD',
  PREGNANT: 'Pregnant',
  NOT_PREGNANT: 'Not Pregnant',
  LIVE_BIRTH: 'Live Birth',
  CHEMICAL: 'Chemical',
  MISSED_MISCARRIAGE: 'Missed Miscarriage',
  MISCARRIAGE: 'Miscarriage',
  STILL_BIRTH: 'Still Birth',
  TFMR: 'TFMR',
  EMBRYOLOGY_REPORT: 'Embryology Report',
  ADD_EMBRYOLOGY_REPORT: 'Add Embryology Report',
  DATE_OF_RETRIEVAL: 'Date of retrieval',
  EGGS_RETRIEVED: 'Eggs Retrieved',
  EGGS_MATURE: 'Eggs Mature',
  EGGS_INSEMINATED: 'Eggs Inseminated',
  MAX_SIZE_20MB: 'Max Size 20mb',
  UPLOAD_FILES: 'Upload Files',
  WITNESS: 'Witness',
  EMBRYOLOGIST: 'EMBRYOLOGIST',
  WITNESS_DATE: 'DATE / TIME',
  MENAPUR_TREATMENT_GRID_HEADER: 'MENAPUR',
  ADJUST_LABEL: 'ADJUST',
  ADJUST_REQUIRED: 'Please choose a start date',
  DESELECT_ALL: 'Deselect all',
  SAVE: 'Save',
  COMMENT_ON_MOVEMENT: 'COMMENT ON MOVEMENT',
  SEMEN_ANALYSIS: 'Semen Analysis',
  ADD_SEMEN_ANALYSIS_REPORT: 'Add Semen Analysis Report',
  REQUESTING_PROVIDER: 'Requesting Provider',
  WITNESSED_BY: 'Witnessed by',
  SIGNED_OFF_BY: 'Signed off by',
  SPERM_TYPE: 'Sperm Type',
  DATE_OF_INITIAL_CRYO: 'Date of Initial Cryo',
  PRESERVATION: 'Preservation',
  SEMEN_SOURCE: 'Semen Source',
  EXAM_REASON: 'Exam Reason',
  SPERM_PREP_METHOD: 'Sperm Prep Method',
  SPERM_TREATMENT_TYPE: 'Sperm Treatment Type',
  SPERM_PREP_SUITABILITY: 'Sperm prep Suitability',
  SAMPLE_FATE: 'Sample Fate',
  INFECTIOUS_STATUS: 'Infectious Status',
  INFECTIOUS_REASON: 'If infectious, reason',
  VOLUME_OF_SPERM_SAMPLE: 'VOLUME OF SPERM SAMPLE',
  TOTAL_SPERM_COUNT: 'Total sperm count',
  NORMAL_MORPHOLOGY: 'Normal morphology',
  NORMAL_MORPHOLOGY_VALIDATION_MSG: 'Normal morphology must be non-negative',
  A_PROGRESSIVE_MOTILE: 'A - Rapid progressive Motile',
  A_PROGRESSIVE_MOTILE_VALIDATION_MSG:
    'A - Rapid progressive Motile must be non-negative',
  B_NON_PROGRESSIVE_MOTILE: 'B - Slow Progressive Motile',
  B_NON_PROGRESSIVE_MOTILE_VALIDATION_MSG:
    'B - Slow Progressive Motile must be non-negative',
  C_NON_MOTILE: 'C - Non Progressive Motile',
  C_NON_MOTILE_VALIDATION_MSG:
    'C - Non Progressive Motile must be non-negative',
  D_IMMOTILE: 'D - Immotile',
  D_IMMOTILE_VALIDATION_MSG: 'D - Immotile must be non-negative',
  GENERAL_COMMENTS: 'General comments',
  MOTILE_SPERM_COUNT: 'Motile sperm count',
  PROGRESSIVE_MOTILE_SPERM_COUNT: 'Progressive motile sperm count',
  TOTAL_MOTILITY: 'Total Motility',
  PROGRESSIVE_MOTILITY: 'Progressive Motility',
  AGGLUTINATION: 'Agglutination',
  TYPE_OF_AGGLUTINATION: 'Type of agglutination',
  MAR_TEST: 'MAR Test',
  MAR_TEST_VALIDATION_MSG: 'MAR Test must be non-negative',
  ANTIBODY_IMMUNOGLOBULIN_A: 'Antibody immunoglobulin A',
  ANTIBODY_IGA_RESULT: 'Antibody iga result',
  ANTIBODY_IGA_RESULT_VALIDATION_MSG:
    'Antibody iga result must be non-negative',
  ANTIBODY_IMMUNOGLOBULIN_G: 'Antibody immunoglobulin g',
  ANTIBODY_IGG_RESULT: 'Antibody igg result',
  ANTIBODY_IGG_RESULT_VALIDATION_MSG:
    'Antibody igg result must be non-negative',
  ROUND_CELL: 'Round cell',
  ROUND_CELL_VALIDATION_MSG: 'Round cell must be non-negative',
  MOVEMENT_COMMENTS: 'Movement comments',
  CRYO_DETAILS: 'CRYO DETAILS',
  PURPOSE_OF_FREEZE: 'Purpose of Freeze',
  NUMBER_OF_VIALS_STORED: 'Number of Vials Stored',
  NUMBER_OF_VIALS_STORED_VALIDATION_MSG:
    'Number of Vials Stored must be non-negative',
  VIAL_TYPE: 'Vial Type',
  CONSENT_EXPIRATION: 'CONSENT EXPIRATION',
  TANK_NO: 'Tank No.',
  STORAGE_LOCATION: 'Storage Location',
  CRYO_WITNESSED_BY: 'CRYO WITNESSED BY',
  COLLECTION_TYPE: 'Collection Type',
  EJACULATED: 'Ejaculated',
  EPIDIDYMAL: 'Epididymal',
  TESTIS: 'Testis',
  ELECTROEJACULATION: 'Electroejaculation',
  RETROGRADE_URINE: 'Retrograde urine',
  CONDOM: 'Condom',
  TESA: 'TESA',
  TESE: 'TESE',
  PESA: 'PESA',
  UNKNOWN: 'Unknown',
  IN_CYCLE: 'In Cycle',
  ANALYSIS: 'Analysis',
  DNA_FRAGMENTATION: 'DNA Fragmentation',
  ZIKA: 'ZIKA',
  HYALURONAN_BINDING_TEST: 'Hyaluronan binding test',
  CULTURE: 'Culture',
  FREEZING: 'Freezing',
  TANK: 'Tank',
  MALE_FERTILITY_ASSESSMENT: 'Male Fertility Assessment',
  DG: 'DG',
  MINI_DG: 'Mini-DG',
  SWIM_UP: 'Swim up',
  WASH_ONLY: 'Wash only',
  IVF: 'IVF',
  ICSI: 'ICSI',
  FREEZE: 'FREEZE',
  PGT: 'PGT',
  IUI_SUITABLE: 'Suitable for IUI',
  IVF_SUITABLE: 'Suitable for IVF',
  ICSI_SUITABLE: 'Suitable for ICSI',
  CONSIDER_SECOND_COLLECTION: 'Consider Second Collection',
  NOT_SUITABLE_FOR_TREATMENT: 'Not suitable for treatment',
  NO_AGGLUTINATION: 'No agglutination',
  ISOLATED: 'Isolated (<10/agglutinate)',
  LARGE: 'Large (>50/agglutinate)',
  MODERATE: 'Moderate (10-50/agglutinate)',
  GROSS: 'Gross (most sperm agglutinated)',
  HEAD_TO_HEAD: 'Head to head',
  TAIL_TO_TAIL: 'Tail to tail',
  TAIL_TIP_TO_TAIL_TIP: 'Tail tip to tail tip',
  MIXED: 'Mixed',
  TANGLED: 'Tangled',
  OWN_USE: 'Own use',
  TREATMENT_BACK_UP: 'Treatment Back-up',
  FERTILITY_PRESERVATION: 'Fertility Preservation', // Used for multiple - do not delete
  SPERM_DONATION: 'Sperm donation',
  STRAWS: 'Straws',
  AMPS: 'Amps',
  VIALS: 'Vials',
  ONE: 'One',
  TWO: 'Two',
  FRESH: 'Fresh',
  THAWED: 'Thawed',
  MIX_OF_FRESH_AND_THAWED: 'Mix of fresh and thawed',
  CYRO: 'CYRO',
  DATE_OF_EXAMINATION: 'DATE OF EXAMINATION',
  ICD_10_CODES_OR_OBSERVATIONS: 'ICD-10 Codes / Observations',
  AFC_LEFT: 'AFC LEFT',
  AFC_RIGHT: 'AFC RIGHT',
  ENDOMETRIAL: 'ENDOMETRIAL',
  MUST_MATCH_THIS_PATTERN: 'Must match this pattern: 12,13,14.5,12',
  ENTER_COMMENT: 'Enter comment',
  POLYPS: 'Polyps',
  LEIOMYOMAS: 'Leiomyomas',
  ADENOMYOSIS: 'Adenomyosis',
  UNICORNUATE: 'Unicornuate',
  SEPTATE: 'Septate',
  SYNECHIAE: 'Synechiae',
  ENDOMETRIOSIS: 'Endometriosis',
  CYST: 'Cyst',
  VARICOCELE: 'Varicocele',
  ENDOMETRIOMA: 'Endometrioma',
  CREATE_EGG_RETRIEVAL_REPORT_ERROR_TOAST_TITLE:
    'Error creating egg retrieval report',
  ADD_EGG_RETRIEVAL_REPORT: 'Add Egg Retrieval Report',
  DATE_OF_PROCEDURE: 'DATE OF PROCEDURE',
  SWAB_COUNT_CORRECT: 'SWAB COUNT CORRECT',
  UNCOMPLICATED_PROCEDURE: 'UNCOMPLICATED PROCEDURE',
  GENERAL_REMARKS: 'GENERAL REMARKS',
  TA_SCAN: 'TA scan',
  FLASH_SEEN: 'Flash seen',
  POSITION_OF_UTERUS: 'Position of uterus',
  CATHETER: 'Catheter',
  BLADDER_STATUS: 'Bladder status',
  EASE_OF_TRANSFER: 'Ease of tranfer',
  EASE_OF_IUI: 'Ease of IUI',
  NO_OF_EMBRYOS_TRANSFERED: 'NO OF EMBRYOS TRANSFERED',
  EMBRYOS_TRANSFERED: 'EMBRYOS TRANSFERED',
  RETROVERTED: 'Retroverted',
  ANTEVERTED: 'Anteverted',
  WALLACE: 'Wallace 23cm',
  EASY: 'Easy',
  DIFFICULT: 'Difficult',
  ABNORMAL_DISCHARGE: 'Abnormal Discharge',
  BLEEDING: 'Bleeding',
  PATIENT_REPORTED_PAIN: 'Patient reported pain',
  SUFFICIENTLY_FULL: 'Suffiicently Full',
  INSTRUCTED_TO_DRINK_MORE: 'Instructed to drink more',
  'N/A': 'N/A',
  MEDICATIONS_ADMINISTERED: 'MEDICATIONS ADMINISTERED',
  VOLUME_OF_SAMPLE_PREPARED: 'Volume of Sample Prepared',
  NORMAL_RANGE: 'Normal Range',
  PROGRESSION: 'Progression',
  ROUND_CELLS: 'Round cells (million/ml)',
  WITNESS_SEMEN_PREP: 'Witness semen prep',
  WITNESS_CRYO_STORAGE: 'Witness CRYO Storage',
  CONSENT_EXPIRY: 'Consent Expiry',
  NORMAL_RANGE_DESCRIPTION:
    'Normal Range based on WHO laboratory manual for the examination and processing of human semen, 6th Edition.',
  CONVERSATION: 'Conversation',
  SPERM_PREPARATION: 'Sperm Preparation',
  EGG_COLLECTION_ID: 'Egg Collection ID',
  EGG_COLLECTION_DISHES: 'Egg Collection Dishes',
  STRIPPING: 'Stripping',
  MOVE_TO_EMBRYO_CULTURE_DISH: 'Move to Embryo Culture Dish',
  MOVE_TO_EMBRYO_TRANSFER_DISH: 'Move to Embryo Transfer Dish',
  EMBRYO_TRANSFER_ID_AND_DISHES_MATCH: 'Embryo Transfer ID and Dishes Match',
  TUBING: 'Tubing',
  PGTA_MEDIA_COLLECTED_BY: 'PGTA Media Collected By',
  LOAD_TO_BIOPSY_DISH: 'Load to Biopsy Dish',
  CRYO_LOCATION: 'Cryo Location',
  EMBRYO_GLUE: 'Embryo Glue',
  DATE_LABEL: 'DATE',
  TO_LABEL: 'TO',
  DATE_REQUIRED: 'Please enter a date',
  TIME_REQUIRED: 'Please enter a time',
  TASK_PERFORMED_REQUIRED: 'Please enter a performed task',
  TASK_PERFORMED_LABEL: 'TASK PERFORMED',
  CONSUMABLES: 'CONSUMABLES',
  EMBRYO_DEVELOPMENT: 'Embryo development',
  ADD_COMMENT: 'Add comment',
  EGG_COMMENTS: 'Egg comments',
  SPERM_COMMENTS: 'SPerm comments',
  EMBRYO_COMMENTS: 'Embryo comments',
  ERROR_FETCHING_EMBRYO_DEVELOPMENT_COMMENTS:
    'Error fetching embryo development comments',
  ERROR_UPDATING_EMBRYO_DEVELOPMENT_COMMENTS:
    'Error updating embryo development comments',
  ADD_TRANSFER_REPORT: 'Add Embryo Transfer Report',
  CHECKLIST_TEMPLATE: 'Checklist template',
  ONE_OFF_TO_DO_ITEM: 'One off todo item',
  ADD_CHECKLIST: 'Add checklist',
  CHOOSE_LIST_TEMPLATE: 'Choose list template',
  ADD_TASK: 'Add task',
  TASK_NAME: 'Task name',
  SCHEDULED_FOR_LATER: 'Scheduled for later',
  PENDING: 'Pending',
  OVERDUE: 'Overdue',
  CANCELLED: 'Cancelled',
  OUTSTANDING: 'Outstanding',
  UPDATE_APPOINTMENT_STATUS_ERROR_TOAST_TITLE:
    'Error updating appointment status',
  PRE_ARRIVAL: 'Pre-Arrival',
  NO_SHOW: 'No Show',
  CHECKED_IN: 'Checked In',
  COMPLETED: 'Completed',
  PRE_PROCEDURE_CHECK: 'Pre-Procedure Check',
  PROCEDURE: 'Procedure',
  POST_PROCEDURE_CHECK: 'Post-Procedure Check',
  DISCHARGED: 'Discharged',
  ULTRASOUND_BLOODWORK: 'Ultrasound/Bloodwork',
  WAITING_FOR_RESULTS: 'Waiting for Results',
  RESULTS_AVAILABLE: 'Results Available',
  REVIEWED_BY_DR: 'Reviewed by Dr',
  REVIEWED_BY_PROVIDER: 'Reviewed by Provider',
  INSTRUCTIONS_GIVEN_TO_PATIENTS: 'Instructions Given to Patients',
  DIAGNOSTICS: 'Diagnostics',
  IN_APPOINTMENT: 'In Appointment',
  WITH_NURSE: 'With Nurse',
  ULTRASOUND: 'Ultrasound',
  CONSULTATION: 'Consultation',
  CONSULT: 'Consult',
  TREATMENT_PLAN: 'Treatment Plan',
  COUNSELING: 'Counseling',
  DISCHARGE: 'Discharge',
  PRE_OP: 'Pre-Op',
  POST_OP: 'Post-Op',
  OVULATION_INDUCTION: 'Ovulation Induction',
  EGG_FREEZING: 'Egg Freezing',
  IVF_ET: 'IVF + ET',
  MOCK_CYCLE_ERA: 'Mock Cycle/ERA',
  SHARED_MOTHERHOOD: 'Shared Motherhood',
  DONOR_GAMETE_MATCHING: 'Donor Gamete Matching',
  ARTIFICIAL_OOCYTE_ACTIVATION: 'Artificial Oocyte Activation',
  OOCYTE_ASSESSMENT: 'Oocyte Assessment (AI)',
  EGG_OR_EMBRYO_THAW: 'Egg or embryo Thaw',
  SPERM_ZYMOT: 'Sperm Zymot',
  SPERM_MOTILITY_ENHANCER: 'Sperm Motility Enhancer',
  PIMSI_PICSI_IMSI: 'PIMSI (pICSI+IMSI)',
  EMBRYOGEN: 'Embryogen',
  DONOR_SPERM: 'Donor Sperm',
  ENDOMETRIAL_SCRATCH: 'Endometrial Scratch',
  HISTOLOGY: 'Histology (small sample)',
  MOCK_EMBRYO_TRANSFER: 'Mock Embryo Transfer',
  PGT_A_EMBRACE_UP_TO_5: 'PGT-A/Embrace (up to 5 embryos)',
  PGT_A_EMBRACE_AFTER_5: 'PGT-A/Embrace (p/e after 5 embryos) ',
  PGT_SR_UP_TO_8: 'PGT-SR (Translocation) up to 8 embryos',
  PGT_SR_AFTER_8: 'PGT-SR (p/e after 8 embryos)',
  UNLICENSED_PGTM_CONDITION: 'Unlicensed PGTM condition',
  PGT_M_GENETIC_TEST_DEVELOPMENT: 'PGT-M genetic test development',
  PGT_M_UP_TO_9: 'PGT-M (up to 9 embryos)',
  PGT_M_AFTER_9: 'PGT-M (p/e after 9 embryos)',
  PGT_A: 'PGT-A',
  PGT_A_PLUS: 'PGT-A+',
  PGT_M: 'PGT-M',
  PGT_SR: 'PGT-SR',
  PGT_P: 'PGT-P',
  IN_LONDON: 'In London',
  IN_UK_OUTSIDE_LONDON: 'In UK, outside London',
  IN_INTERNATIONAL: 'In International',
  OUT_UK: 'Out UK',
  OUT_INTERNATIONAL: 'Out International',
  CONFIRM_PRESCRIPTION_HEADER: 'Confirm Prescription',
  IUI_CYCLES_3: '3x IUI cycles',
  EGG_FREEZING_CYCLES_2: '2 x EGG FREEZING CYCLES (package)',
  EGG_FREEZING_CYCLES_3: '3 x EGG FREEZING CYCLES (package)',
  IVF_CYCLES_2: '2 x IVF cycles',
  IVF_CYCLES_3: '3 x IVF cycles',
  SAVE_PATIENT_CTA: 'Save patient',
  SAVED_PATIENT_SUCCESS_TOAST_TITLE: 'Patient saved successfully',
  SAVED_PATIENT_AND_REDIRECT_TO_CONFIRM_EMAIL_SUCCESS_TOAST_TITLE:
    'Patient saved successfully, redirecting to confirm email',
  SAVED_STAFF_SUCCESS_TOAST_TITLE: 'Staff saved successfully',
  ADDRESS: 'Address',
  CREATE_TASK_SUCCESS_TOAST_TITLE: 'Task created successfully',
  CREATE_INSURANCE_SUCCESS_TOAST_TITLE: 'Insurance created successfully',
  UPDATE_INSURANCE_SUCCESS_TOAST_TITLE: 'Insurance updated successfully',
  CREATE_NEW_CYCLE_SUCCESS_TOAST_TITLE: 'Cycle added successfully',
  SEARCH_PATIENT_PLACEHOLDER: 'Search for patient',
  PATIENT_REQUIRED: 'Please choose a patient',
  PATIENT_LABEL: 'PATIENT',
  CHOOSE_PHYSICIAN_LABEL: 'CHOOSE PROVIDER / RESOURCE',
  CHOOSE_PHYSICIAN_PLACEHOLDER: 'Add provider, resources',
  DATE_OF_APPOINTMENT_REQUIRED: 'Please add appointment date',
  TIME_LABEL: 'TIME',
  START_TIME_BEFORE_END_TIME: 'Start time must be before end time',
  END_TIME_AFTER_START_TIME: 'End time must be after start time',
  LOCATION_LABEL: 'LOCATION',
  SET_ORDERS_TITLE: 'Set Orders',
  SET_PRESCRIPTION_TITLE: 'Set Prescription',
  APPOINTMENT_PURPOSE_REQUIRED: 'Appointment purpose is required',
  APPOINTMENT_PURPOSE_LABEL: 'APPOINTMENT PURPOSE',
  APPOINTMENT_PURPOSE_DEFAULT_OPTION: 'Please choose an appointment purpose',
  DIAGNOSTIC: 'Diagnostic',
  CONSULT_NOTES: 'Consult notes',
  PROCEDURE_NOTES: 'Procedure Notes',
  NOTES_FOR_PATIENT_LABEL: 'NOTES FOR PATIENT',
  NOTES_FOR_PATIENT_PLACEHOLDER: 'Notes for patient',
  INSTRUCTIONS_LABEL: 'INSTRUCTIONS',
  CONFIRM: 'CONFIRM',
  PROGESTERONE_SUP_400: 'Progesterone SUP 400',
  NEW_PATIENT_CONSULTATION_IN_PERSON: 'New Patient Consultation (in person)',
  NEW_PATIENT_CONSULTATION_VIRTUAL: 'New Patient Consultation (virtual)',
  EXISTING_PATIENT_CONSULTATION: 'Existing Patient Consultation',
  SURROGATE_CONSULTATION: 'Surrogate consultation',
  HEALTHCARE_PROFESSIONAL_VIRTUAL:
    'Consultation with Healthcare Professional (virtual)',
  HEALTHCARE_PROFESSIONAL_IN_PERSON:
    'Consultation with Healthcare Professional (in person)',
  COUNSELLING_CONSULTATION_VIRTUAL: 'Counselling Consultation (virtual)',
  ANAESTHETIC_REVIEW: 'Anaesthetic review',
  PREGNANCY_DATING_SCAN: 'Pregnancy dating Scan - 9-15 weeks',
  NIPT_SCAN_CHROMOSOMES:
    'NIPT Scan and blood test - 10-40 weeks - chromosomes 21,18,13, X&Y',
  NIPT_SCAN_ALL_CHROMOSOMES:
    'NIPT Scan and blood test - 10-40 weeks - all chromosomes',
  PREGNANCY_WELLBEING_ASSURANCE_SCAN:
    'Pregnancy wellbeing Assurance Scan - 12-24 weeks',
  GENDER_SCAN: 'Gender Scan - 16-32 weeks',
  GENDER_REVEAL_SCAN: 'Gender Reveal Scan (including Canon) - 16 - 32 weeks',
  CERVICAL_LENGTH_SCAN: 'Cervical Length Scan - 16-40 weeks',
  ANOMALY_SCAN: 'Anomaly Scan - 19-24 weeks',
  GROWTH_SCAN: 'Growth Scan - 24-36 weeks',
  FOUR_D_SCAN: '4D scan - 24-34 weeks',
  PRESENTATION_SCAN: 'Presentation Scan - 35 weeks - term',
  MEDICATED_TIMED_INTERCOURSE: 'Medicated Timed Intercourse (MTIC)',
  PREGNANCY_TEST_BLOODWORK: 'Pregnancy test (bloodwork)',
  OB_ULTRASOUND_TRANSVAGINAL: 'OB Ultrasound (Transvaginal)',
  NEW_PATIENT_CONSULTATION: 'New Patient Consultation',
  NEW_PATIENT_EGG_FREEZING: 'New Patient Egg Freezing',
  FOLLOW_UP_VISIT: 'Follow-up Visit General',
  FOLLOW_UP_VISIT_IVF_PREP: 'Follow-up Visit IVF Prep',
  FOLLOW_UP_VISIT_PGTA_RESULTS: 'Follow-up Visit PGTA Results',
  FOLLOW_UP_VISIT_PREGNANCY_TEST: 'Follow-up Visit Pregnancy Test',
  FOLLOW_UP_VISIT_POST_OP: 'Follow-up Visit Post-Op',
  PHONE_CONSULT: 'Phone Consult',
  FRESH_DONOR_CONSULTATION: 'Fresh Donor Consultation',
  TRIAL_EMBRYO_TRANSFER: 'Trial Embryo Transfer',
  BASELINE: 'Baseline',
  FROZEN_EMBRYO_TRANSFER: 'Frozen Embryo Transfer',
  FRESH_EMBRYO_TRANSFER: 'Fresh Embryo Transfer',
  EMBRYOLOGY: 'Embryology',

  BASELINE_IVF: 'Baseline - IVF',
  BASELINE_IUI: 'Baseline - IUI',
  BASELINE_FET: 'Baseline - FET',
  BASELINE_TIMED_INTERCOURSE: 'Baseline - Timed Intercourse',
  MONITORING_IVF: 'Monitoring - IVF',
  MONITORING_IUI: 'Monitoring - IUI',
  MONITORING_FET: 'Monitoring - FET',
  MONITORING_TIMED_INTERCOURSE: 'Monitoring - Timed Intercourse',
  MONITORING_EXTERNAL: 'Monitoring - External',
  LUTEAL_LABS: 'Luteal Labs',
  OUTSIDE_MONITORING: 'Outside Monitoring',
  DOWN_REGULATION_FET: 'Down Regulation - FET',
  DOWN_REGULATION_IVF: 'Down Regulation - IVF',
  NURSE_INJECTION: 'Nurse Injection',
  MONITORING_POST_TRIGGER: 'Monitoring - Post Trigger',
  SPERM_PREP_IUI: 'Sperm Prep for IUI',
  SPERM_PREP_IUI_THAW: 'Sperm Prep for IUI (thaw)',
  SPERM_PREP_IVF: 'Sperm Prep for IVF',
  Pregnancy: 'Pregnancy',
  CONFIRMED: 'Confirmed',
  PARTIAL_RESULTS: 'Partial Results',
  INSTRUCTIONS_REQUIRED: 'Instructions Required',
  INSTRUCTIONS_GIVEN_TO_PATIENT: 'Instructions Given to Patient',
  ANTRAL_FOLLICLE_COUNT: 'Antral Follicle Count (AFC) US',
  HSG_HYCOSY_HYFOSY: 'HSG/HyCoSy/HyFoSy',
  SALINE_SONOHYSTEROGRAM: 'Saline Sonohysterogram',
  CYST_CHECK: 'Cyst check',
  HYSTEROSCOPY: 'Hysteroscopy',
  WOMENS_EXAM: 'Womens Exam',
  URINALYSIS: 'Urinalysis',
  TRANSABDOMINAL_SCAN: 'Transabdominal scan',
  ENDOMETRIAL_BIOPSY: 'Endometrial Biopsy',
  POST_MENOPAUSE_PELVIC_SCAN: 'Post Menopause Pelvic Scan',
  WOMAN_WELLNESS_SCAN: 'Woman wellness scan',
  TESTES_AND_SCROTUM_SCAN: 'Testes and scrotum scan',
  BLOOD_DRAW_ONLY: 'Blood Draw Only',
  SPERM_FREEZE: 'Sperm freeze',
  MONITORING: 'Monitoring',
  EGG_RETRIEVAL: 'Egg Retrieval',
  TRANSFER: 'Transfer',
  APPOINTMENT_ROOM_REQUIRED: 'Please fill the appointment room',
  APPOINTMENT_ROOM_LABEL: 'ROOM',
  APPOINTMENT_ROOM_DEFAULT_OPTION: 'Please choose an appointment room',
  VIRTUAL: 'Virtual',
  SCAN_ROOM: 'Scan room',
  THEATER: 'Theater',
  SEE_ALL: 'See all',
  ADD_APPOINTMENT: 'Add appointment',
  SCHEDULED_APPOINTMENT: 'Scheduled appointment',
  CONFIRM_ORDERS: 'Confirm Orders',
  CONFIRM_RESULTS: 'Confirm Results',
  SCHEDULE_SECOND_CONSULTATION_APPOINTMENT:
    "Schedule 2nd Consultation Appointment, if haven't already",
  ADD_CRYOTRANSPORT_LIST: 'add Cryotransport List',
  CONFIRM_THAW_INSTRUCTIONS_FOR_EMBRYO_TRANSFER:
    'Confirm Thaw instructions for Embryo Transfer',
  COUNSELLING_RECEIVED: 'Counselling received',
  DONOR_CLEARED_FOR_USE: 'Donor cleared for use?',
  EGG_RECIPIENT_SCREENS_PERFORMED: 'egg recipient screens performed',
  EXTENDED_SCREENING_CARRIER_CONDITIONS:
    'Extended Screening (Carrier conditions)',
  FAMILY_LIMIT_CHECKED_AND_COMPLIANT:
    'Family limit checked and compliant (<10)',
  FEMALE_AND_MALE_QUESTIONNAIRE: 'FEMALE AND MALE QUESTIONNAIRE',
  HFEA_CD_CONSENT_TO_DISCLOSURE_FEMALE:
    'HFEA CD ,CONSENT TO DISCLOSURE (FEMALE)',
  HFEA_CD_CONSENT_TO_DISCLOSURE_MALE: 'HFEA CD, CONSENT TO DISCLOSURE (MALE)',
  HFEA_DONOR_INFORMATION_DI_FORM: 'HFEA Donor information (DI) Form',
  HFEA_MT_USE_AND_STORAGE_OF_SPERM_OR_EMBRYOS_MALE:
    'HFEA MT, USE & STORAGE OF SPERM OR EMBRYOS (MALE)',
  HFEA_WD_FORM: 'HFEA WD Form',
  ICSI_CONSENT: 'ICSI CONSENT',
  IVF_EMBRYO_FREEZING_CONSENT: 'IVF EMBRYO FREEZING CONSENT',
  IVF_RECIPIENT_CONSENT_FORM_COMPLETED_WITH_COUNSELLOR:
    'IVF , RECIPIENT CONSENT FORM (COMPLETED WITH COUNSELLOR)',
  IVF_EGG_RETRIEVAL_EMBRYO_REPLACEMENT_CONSENT:
    'IVF EGG RETRIEVAL/EMBRYO REPLACEMENT CONSENT',
  KARYOTYOPE_NORMAL: 'Karyotyope (normal)?',
  EDI_ENTERED_NEW_PATIENT_DETAILS: 'EDI ENTERED – NEW PATIENT DETAILS',
  ENSURE_PROPER_CONSENTS_SIGNED: 'Ensure proper consents signed',
  ENSURE_PATIENT_HAS_SELECTED_A_DONOR_AND_ARRANGED_FOR_SAMPLE_DELIVERY_TO_LAB:
    'Ensure patient has selected a donor and arranged for sample delivery to lab',
  ENSURE_PATIENT_HAS_HAD_GENETIC_SCREENING_COMPLETED_AND_RESULTS_RECEIVED:
    'Ensure patient has had genetic screening completed and results received',
  ENSURE_PATIENT_HAS_HAD_IMPLICATIONS_COUNSELLING:
    'Ensure patient has had Implications Counselling',
  IF_DOING_IUI_ONCE_IUI_SCHEDULED_PATIENT_OR_CLINICAL_STAFF_NEEDS_TO_GIVE_GREEN_LIGHT_TO_LAB_TO_THAW_SPERM:
    "If doing IUI, once IUI scheduled, patient or clinical staff needs to give green light to lab to thaw sperm. Many clinics will do back-to-back IUI's on successive days",
  INFO_REGARDING_DONOR_SPERM_IF_RELEVANT_HOW_TO_CHOOSE_ORDER_SHIP_CONSENT_FORMS_CONTACT_INFO_FOR_LAB_TO_COORDINATE_SHIPMENT:
    'info regarding donor sperm if relevant (how to choose/order/ship, consent forms, contact info for lab to coordinate shipment)',
  OPERATIVE_CARE_PLAN_AND_CHECKLIST_UPLOADED_TO_EMR:
    'Operative Care Plan and Checklist Uploaded to EMR',
  CONFIRM_ALLERGIES: 'Confirm allergies',
  VERIFY_SIGNED_CONSENT_ON_FILE: 'Verify Signed Consent on file',
  ENSURE_PATIENT_HAS_ALL_PROCEDURE_INSTRUCTIONS_INCLUDING_TIME_TO_ARRIVE_PARTNER_TIME:
    'Ensure patient has all procedure instructions including time to arrive, partner time',
  POST_OP_INSTRUCTIONS_BOTH_WHAT_TO_EXPECT_MEDS_WHEN_TO_RETURN_TO_OFFICE:
    'Post-Op instructions - both what to expect, meds, when to return to office',
  ADD_EGG_REPORT_TO_CYCLE_CHART: 'Add Egg Report to Cycle Chart',
  VERIFY_CONSENTS_ON_FILE: 'Verify consents on file',
  ENSURE_PATIENT_HAS_ALL_PROCEDURE_INSTRUCTIONS_INCLUDING_TIME_TO_ARRIVE_AND_INSTRUCTIONS_TO_FILL_BLADDER:
    'Ensure patient has all procedure instructions including time to arrive, and instructions to fill bladder',
  POST_TRANSFER_INSTRUCTIONS_WITH_MEDICATION_INSTRUCTIONS_AS_WELL_AS_PREGNANCY_TEST_DATE:
    'Post-transfer instructions, with medication instructions as well as pregnancy test date',
  FILL_OUT_PROCEDURE_REPORT: 'Fill out procedure report',
  UPDATE_PATIENT_WITH_FERT_REPORT: 'Update patient w/fert report',
  UPDATE_PATIENT_WITH_BLAST_REPORT: 'Update patient w/Blast report',
  UPDATE_PATIENT_WITH_PGT_RESULT_IF_APPLICABLE:
    'Update patient w/PGT result (if applicable)',
  ADD_CHECKLIST_FOR_CARRIER_MATCH_TEST: 'Add checklist for carrier-match test',
  ADD_DONOR_GAMETES_CHECKLIST: 'Add donor gametes checklist',
  ADD_EGG_DONOR_CHECKLIST: 'Add egg donor checklist',
  COMPLETED_EGG_DONOR_QUESTIONAIRE: 'Completed Egg Donor Questionaire',
  DONOR_CONSENT_FORM_COMPLETED_WITH_COUNSELLOR:
    'DONOR CONSENT FORM (COMPLETED WITH COUNSELLOR)',
  DONOR_PATIENT_HISTORY_RECEIVED: 'donor patient history received',
  EGG_DONOR_LETTER_TO_PCP: 'egg donor letter to PCP',
  HAVE_SEEN_COUNSELLORS: 'HAVE SEEN COUNSELLORS',
  HFEA_CD_CONSENT_TO_DISCLOSURE: 'HFEA CD , CONSENT TO DISCLOSURE',
  HFEA_WD_CONSENT_TO_USE_AND_STORAGE_OF_DONATED_EGGS:
    'HFEA WD , CONSENT TO USE & STORAGE OF DONATED EGGS',
  ANY_PREVIOUS_GENETIC_TEST_RESULT: 'Any previous genetic test result',
  CONSANGUINITY: 'Consanguinity',
  CONSENT_FORMS_SIGNED_BY_COUPLE_AND_CLINICIAN:
    'Consent forms signed by couple and clinician',
  CONSENT_TO_RECEIVE_RESULTS_BY_EMAIL: 'Consent to receive results by email',
  CONSENT_TO_SHARE_EACH_OF_THEIR_RESULTS_WITH_THE_OTHER_PARTNER:
    'Consent to share each of their results with the other partner',
  COUPLE_INFORMED_THAT_RESULTS_ARE_GIVEN_FOR_A_COUPLE_TOGETHER:
    'Couple informed that results are given for a couple together',
  DEVELOPMENT_OF_PGD_TEST_MAY_DELAY_PLANNED_TREATMENT:
    'Development of PGD test may delay planned treatment',
  DISCUSSED_ACTIONS_TO_TAKE: 'Discussed Actions to take',
  DISCUSSION_OF_IMPLICATIONS: 'Discussion of Implications',
  EXPLAINED_AUTOSOMAL_RECESSIVE_INHERITANCE:
    'Explained Autosomal Recessive inheritance',
  EXPLAINED_X_LINKED_INHERITANCE: 'Explained X-Linked inheritance',
  LIMITATIONS_OF_TEST_TO_THESE_600_GENES_AND_NO_OTHERS:
    'Limitations of test to these 600 genes and no others',
  NO_INCREASED_RISK_IF_THEY_CARRY_DIFFERENT_GENES:
    'No increased risk if they carry different genes',
  OPTION_OF_PGD_IF_AT_RISK: 'Option of PGD if at risk',
  PATIENT_BROCHURE_GIVEN: 'Patient brochure given',
  RECESSIVE_CARRIER_COUPLE_25_PERCENT_AFFECTED_CHILD:
    'Recessive carrier couple 25% affected child',
  RELATIVES_MAY_BE_CARRIERS_THEY_CAN_INFORM_SIBLINGS_AND_OTHER_FAMILY_MEMBERS_OF_RESULTS:
    'Relatives may be carriers - they can inform siblings and other family members of results',
  REQUISITION_FORM_COMPLETED_AND_SIGNED_BY_COUPLE_AND_CLINICIAN:
    'Requisition form completed and signed by couple and clinician',
  REVIEWED_FAMILY_HISTORY: 'Reviewed Family history',
  SMALL_CHANCE_OF_INCIDENTAL_FINDING_MALE_X_LINKED_RESULT_BRCA2:
    'Small chance of incidental finding (male X-linked result; BRCA2)',
  SMALL_RESIDUAL_RISK_OF_AN_UNDETECTABLE_MUTATION_IN_ANY_OF_THESE_GENES:
    'Small residual risk of an undetectable mutation in any of these genes',
  TIMEFRAME_FOR_RESULTS: 'Timeframe for results',
  X_L_CARRIER_25_PERCENT_RISK_OF_AFFECTED_SON:
    'X-L carrier 25% risk of affected son',
  TRIGGER_INSTRUCTIONS_INFORMATION: 'Trigger Instructions/information',
  ENSURE_PATIENTS_HAVE_TRIGGER_MEDICATION_AND_REMEMBER_HOW_TO_INJECT:
    'Ensure patients have trigger medication and remember how to inject',
  ENSURE_BLOOD_TEST_ORDERS_PLACED_AND_APPOINTMENT_SET_FOR_BLOOD_PREGNANCY_TEST:
    'Ensure blood test orders placed and appointment set for blood pregnancy test',
  WHEN_PATIENTS_CALL_WITH_DAY_1_ENSURE_ALL_PRE_TREATMENT_TESTING_AND_PAPERWORK_COMPLETE_CONSENTS_IN_PLACE_AND_INITIATE_TREATMENT_CYCLE:
    'When patients call with day 1, ensure all pre-treatment testing and paperwork complete, consents in place, and initiate treatment cycle',
  ENSURE_PATIENTS_HAVE_ALL_MEDICATIONS: 'Ensure patients have all medications',
  PLACE_PROPER_ORDERS_FOR_MONITORING_APPT_AND_ENSURE_PT_HAS_SCHEDULED_MONITORING_APPTS_CONVERT_INTENDED_TREATMENT_TO_CYCLE:
    'Place proper orders for monitoring appt and ensure pt has scheduled monitoring appts (convert intended treatment to cycle)',
  INFORMATION_ABOUT_NEXT_STEPS_TREATMENT_PROTOCOL_INFORMATION:
    'Information about next steps - treatment protocol information',
  CONSENTS_FOR_TREATMENT_TYPE: 'Consents for treatment type',
  ORDER_MEDS_FOR_TREATMENT: 'Order meds for treatment',
  MEDICATION_EDUCATION_STORAGE_GUIDELINES_INJECTION_TEACHING:
    'Medication education - storage guidelines, injection teaching',
  ENSURE_ALL_DIAGNOSTIC_TESTING_CONSENTS_FINANCIAL_AUTH_HAS_BEEN_COMPLETED_SO_TREATMENT_CAN_START_W_O_DELAY:
    'Ensure all diagnostic testing/consents/financial auth has been completed so treatment can start w/o delay',
  PROVIDE_INFO_ABOUT_PATIENT_EDUCATION_CLASSES_MODULES_VIDEOS_ETC:
    'Provide info about patient education - classes, modules, videos, etc',
  ENSURE_RECEIPT_OF_COMPLETE_CONSENTS: 'Ensure receipt of complete consents',
  WHEN_PATIENTS_COME_IN_FOR_MONITORING_ENSURE_ANY_QUESTIONS_CONCERNS_ARE_ADDRESSED:
    'When patients come in for monitoring, ensure any questions/concerns are addressed',
  REVIEW_MONITORING_RESULTS_W_MD_ENSURE_PROPER_INSTRUCTIONS_ARE_INPUT_INTO_THE_SYSTEM:
    'Review monitoring results w/MD, ensure proper instructions are input into the system',
  CONTACT_PATIENT_WITH_MONITORING_RESULTS_DOSING_AND_RETURN_TO_OFFICE_INSTRUCTIONS_ENSURE_PROPER_ORDERS_PLACED_AND_APPT_MADE_FOR_THE_NEXT_MONITORING_APPT:
    'Contact patient with monitoring results, dosing, and return-to-office instructions. Ensure proper orders placed and appt made for the next monitoring appt',
  SCHEDULE_APPT_WITH_MD_TO_DISCUSS_FAILED_CYCLE_NEXT_STEPS_TREATMENT_OPTIONS:
    'schedule appt with MD to discuss failed cycle, next steps, treatment options',
  END_CYCLE_ADD_OUTCOME_REPORT: 'End Cycle / Add outcome Report',
  SCHEDULE_FOLLOWUP_CALL_WITH_DR: 'Schedule Followup Call with Dr',
  PATIENT_INSTRUCTED_TO_STOP_MEDS_AND_CALL_WITH_THE_START_OF_THE_NEXT_CYCLE:
    'Patient Instructed to stop meds, and call with the start of the next cycle',
  ORDERS_FOR_PATIENTS_INFECTIOUS_DISEASE_AND_GENERAL_HEALTH_SCREENING:
    'Orders for patients (infectious disease and general health screening)',
  ORDERS_FOR_PARTNER_IDBW: 'Orders for partner (IDBW)',
  ORDERS_FOR_PATIENTS_DAY_3: 'Orders for patients - Day 3',
  GENETIC_SCREENING_BLOODWORK_FOR_PATIENTS:
    'Genetic screening bloodwork (for patients)',
  ORDER_ULTRASOUND_AFC_FOR_PATIENTS: 'Order Ultrasound - AFC for patients',
  ORDER_AND_GIVE_INFO_SHEET_FOR_HSG_OR_SALINE_SONOGRAM:
    'Order and give info sheet for HSG or saline sonogram',
  PROVIDE_INFORMATION_FOR_FINANCIAL_SERVICES:
    'Provide information for Financial services',
  INFO_SHEET_AND_ORDER_FOR_SEMEN_ANALYSIS_FOR_THE_PARTNER:
    'Info sheet and order for semen analysis for the partner',
  NECESSARY_CONSENTS_HSG_SEMEN_ANALYSIS:
    'necessary consents (HSG, semen analysis)',
  INFO_AS_NEEDED_FOR_A_UROLOGIST_FOR_THE_PARTNER:
    'info as needed for a urologist for the partner',
  ENTER_ORDER_RESULTS_INTO_EMR: 'Enter Order results into EMR',
  FOLLOW_UP_WITH_PATIENTS_FOR_DAY_1_TO_SCHEDULE_A_DIAGNOSTIC_APPOINTMENT:
    'Follow up with patients for Day 1 to schedule a diagnostic appointment',
  GENETIC_SCREENING_BLOODWORK_FOR_THE_PARTNER_IF_NECESSARY:
    'Genetic screening bloodwork (for the partner, if necessary)',
  PRESCRIPTION_FOR_ANTIBIOTIC_FOR_HSG_IF_NEEDED:
    'prescription for antibiotic for HSG if needed',
  ADD_ALLERGIES_TO_THE_FLAGS_SECTION_IF_NECESSARY:
    'Add allergies to the flags section if necessary',
  CONFIRM_PATIENT_ONBOARDING_MEDICAL_HISTORY:
    'Confirm Patient Onboarding Medical History',
  ENSURE_ANY_BLOODWORK_TESTING_THAT_NEEDS_TO_BE_UPDATED_REGULARLY_IS_DONE_OFTEN_RELATES_TO_INSURANCE_REQUIREMENTS:
    'Ensure any bloodwork/testing that needs to be updated regularly is done (often relates to insurance requirements)',
  IF_THE_PREGNANCY_TEST_IS_POSITIVE_SCHEDULE_BETA_PLACE_ORDERS_AND_MAKE_AN_APPT_REVIEW_MEDICATION_INSTRUCTIONS:
    'If the pregnancy test is positive, schedule Beta, place orders, and make an appt. Review medication instructions',
  IF_SUFFICIENT_LEVELS_REPEAT_BETA_APPROXIMATELY_2_DAYS_LATER:
    'If sufficient levels, repeat BETA approximately 2 days later',
  IF_SUFFICIENT_RISE_SCHEDULE_OB_SCAN_FOR_6_WEEKS:
    'If sufficient rise, schedule OB scan for 6 weeks',
  IF_SUB_OPTIMAL_HCG_RISE_60_PERCENT_ANY_PAIN_OR_SPOTTING_REFER_EPAU_FOR_SUSPICION_OF_ECTOPIC:
    'If sub-optimal hcg rise <60% + any pain or spotting--> refer EPAU for suspicion of ectopic',
  SIX_WEEK_SCAN_IS_CLEAR:
    'If the 6-week scan is clear, schedule OB scan for 10 weeks',
  LEVELS_RISE_BUT_ABNORMALLY:
    'If levels rise, but abnormally, additional bloodwork may be ordered, and ectopic/miscarriage loss precautions may need to be given',
  GIVE_DISCHARGE_INSTRUCTIONS_AND_REFER:
    'Give discharge instructions and refer to OB/MFM',
  PRISM_ENTERED: 'PRISM entered',
  CONSENT_FORMS: 'CONSENT FORMS',
  SURROGATE_QUESTIONNAIRE: 'SURROGATE QUESTIONNAIRE',
  PCP_LETTER: 'PCP Letter',
  SURROGACY_CONSENT_FORM_COMPLETED_WITH_COUNSELLOR:
    'SURROGACY CONSENT FORM (COMPLETED WITH COUNSELLOR)',
  HFEA_WSG_USE_AND_STORAGE_OF_EGGS_OR_EMBRYOS_ONLY_IF_PROVIDING_EGGS:
    'HFEA WSG USE & STORAGE OF EGGS OR EMBRYOS (ONLY IF PROVIDING EGGS)',
  ICSI_CONSENT_ONLY_IF_PROVIDING_EGGS: 'ICSI CONSENT, (ONLY IF PROVIDING EGGS)',
  EDI_ENTERED_NEW_INFORMATION: 'EDI ENTERED/NEW INFORMATION',
  EGG_DONOR_QUESTIONNAIRE: 'EGG DONOR QUESTIONNAIRE',
  SPERM_DONOR_QUESTIONNAIRE: 'SPERM DONOR QUESTIONNAIRE',
  IVF_HAMMERSMITH_SURROGACY_CONSENT_FORM_COMPLETED_WITH_COUNSELLOR:
    'IVF HAMMERSMITH SURROGACY CONSENT FORM (COMPLETED WITH COUNSELLOR)',
  IVF_HAMMERSMITH_TVOR_ET_CONSENT_IF_HAVING_TVOR:
    'IVF HAMMERSMITH TVOR /ET CONSENT (IF HAVING TVOR)',
  IVF_HAMMERSMITH_CONSENT_TO_FREEZE_EMBRYOS_IF_HAVING_TVOR:
    'IVF HAMMERSMITH CONSENT TO FREEZE EMBRYOS (IF HAVING TVOR)',
  HFEA_WT_IF_HAVING_TVOR_AND_LEAVING_EMBRYOS_TO_MALE_FOR_USE_IN_SURROGACY_OR_IF_EMBRYOS_WERE_ORIGINALLY_FROZEN_FOR_OWN_USE:
    'HFEA WT (IF HAVING TVOR & LEAVING EMBRYOS TO MALE FOR USE IN SURROGACY OR IF EMBRYOS WERE ORIGINALLY FROZEN FOR OWN USE)',
  HFEA_WSG_USE_AND_STORAGE_OF_EGGS_OR_EMBRYOS_FEMALE:
    'HFEA WSG, USE & STORAGE OF EGGS OR EMBRYOS (FEMALE)',
  HFEA_WD_IF_LEAVING_EMBRYOS_TO_MALE_FOR_USE_IN_SURROGACY:
    'HFEA WD (IF LEAVING EMBRYOS TO MALE FOR USE IN SURROGACY)',
  HFEA_MSG_FORM_USE_AND_STORAGE_OF_SPERM_OR_EMBRYOS_MALE:
    'HFEA MSG FORM USE & STORAGE OF SPERM OR EMBRYOS (MALE)',
  HFEA_MD_IF_LEAVING_EMBRYOS_TO_FEMALE_FOR_USE_IN_SURROGACY:
    'HFEA MD (IF LEAVING EMBRYOS TO FEMALE FOR USE IN SURROGACY)',
  HFEA_MT_IF_LEAVING_EMBRYOS_TO_FEMALE_FOR_USE_IN_SURROGACY:
    'HFEA MT (IF LEAVING EMBRYOS TO FEMALE FOR USE IN SURROGACY)',
  HFEA_CD_FORM_CONSENT_TO_DISCLOSURE_FEMALE:
    'HFEA CD FORM, CONSENT TO DISCLOSURE (FEMALE)',
  HFEA_CD_FORM_CONSENT_TO_DISCLOSURE_MALE:
    'HFEA CD FORM, CONSENT TO DISCLOSURE (MALE)',
  EDI_NEW_PATIENT_INFO_IF_QUARANTINING_EMBRYOS_FOR_SURROGACY:
    'EDI – NEW PATIENT INFO. IF QUARANTINING EMBRYOS FOR SURROGACY',
  EDI_ENTERED_IF_WHEN_USING_OWN_EGGS_NEW_DONOR_INFORMATION:
    'EDI ENTERED – IF / WHEN USING OWN EGGS NEW DONOR INFORMATION',
  EDI_ENTERED_IF_WHEN_USING_OWN_SPERM_NEW_DONOR_INFORMATION:
    'EDI ENTERED – IF / WHEN USING OWN SPERM NEW DONOR INFORMATION',
  ALL: 'All',
  UNREAD: 'Unread',
  ARCHIVED: 'Archived',
  START_A_VOICE_CALL: 'Start a voice call',
  START_A_VIDEO_CALL: 'Start a video call',
  CONVERSATION_INFORMATION: 'Conversation information',
  CUSTOMIZE_CHAT: 'Customize Chat',
  SEARCH_IN_CONVERSATION: 'Search in Conversation',
  CHANGE_THEME_STYLING: 'Change Theme Styling',
  CHOOSE_DEFAULT_EMOJI: 'Choose Default Emoji',
  PRIVACY_AND_SUPPORT: 'Privacy & Support',
  TURN_OFF_NOTIFICATIONS: 'Turn off notifications',
  IGNORE_ALL_MESSAGES: 'Ignore all messages',
  BLOCK_USER: 'Block user',
  SOMETHING_IS_WRONG: "Something's Wrong",
  REPORT_THE_CONVERSATION_AND_PROVIDE_FEEDBACK:
    'Report the conversation and provide feedback',
  SHARED_FILES: 'Shared Files',
  YOU_OPENED_IN_THE_PAST_YEAr: 'You opened in the past year',
  YOU_EDITED_THIS_FILE_YESTERDAY: 'You edited this file yesterday',
  NEVER_OPENED: 'Never opened',
  CREATE_ULTRASOUND_SUCCESS_TOAST_TITLE: 'Report created successfully',
  UPDATE_TASK_SUCCESS_TOAST_TITLE: 'Task updated successfully',
  ADD_CHECKLIST_TEMPLATE_SUCCESS_TOAST_TITLE:
    'Checklist template added successfully',
  ADD_CHECKLIST_SUCCESS_TOAST_TITLE: 'Checklist created successfully',
  EMBRYO_TRANSFER: 'Transfer',
  DIAGNOSTIC_APPT: 'Diagnostic appt',
  DONOR_RECEIPIENT: 'Donor Receipient',
  EMBRYO_UPDATE: 'Embryo Update',
  GAMET_DONOR: 'Gamet Donor',
  GENETIC_CARRIER_SCREENING: 'Genetic Carrier Screening',
  NEGATIVE_PREGNANCY_TEST: 'Negative pregnancy test',
  NEW_PATIENT_CONSULT: 'New Patient Consult',
  POSITIVE_PREGNANCY: 'Positive Pregnancy',
  SURROGATE: 'Surrogate',
  SURROGATE_COUPLE: 'Surrogate Couple',
  ALLERGIES: 'Allergies',
  ALLERGIES_INPUT_LABEL: 'ADD ANY KNOWN PATIENT ALLERGIES TO THE FOLLOWING',
  CURRENT_MEDICATIONS: 'Current Medications',
  SURGICAL_HISTORY: 'Surgical History',
  LATEX: 'Latex',
  SOYA: 'Soya',
  NUTS: 'Nuts',
  LOCAL_ANESTHESIA: 'Local Anesthesia',
  GENERAL_ANAESTHETIC: 'General Anaesthetic',
  MEDICATIONS: 'Medications',
  NO_ALLERGIES: 'No Allergies',
  MEDICATIONS_INPUT_LABEL: 'PLEASE NAME THE MEDICATION/S',
  MEDICATIONS_INPUT_REQUIRED: 'Medications are required',
  REASON_FOR_MEDICATIONS_INPUT_LABEL: 'WHAT WAS THE REASON FOR: ',
  REASON_FOR_MEDICATIONS_INPUT_PLACEHOLDER: 'Reason for medications',
  REASON_FOR_MEDICATIONS_INPUT_REQUIRED: 'Reason for medications is required',
  OTHER_REASON_FOR_MEDICATIONS_INPUT_LABEL: 'IF OTHER, PLEASE TELL US MORE',
  OTHER_REASON_FOR_MEDICATIONS_INPUT_PLACEHOLDER:
    'Other reason for medications',
  CREATE_ULTRASOUND_REPORT_ERROR_TOAST_TITLE: 'Error in creating report',
  MEASUREMENT_TYPE: 'Measurement type',
  MEASUREMENT_TYPE_DEFAULT_OPTION: 'Please choose measurement',
  CREATE_LAB_REPORT_SUCCESS_TOAST_TITLE: 'Lab report created successfully',
  CREATE_THAW_REPORT_SUCCESS_TOAST_TITLE: 'Thaw report created successfully',
  PATIENT_PLACEHOLDER: 'Patient Name',
  APPOINTMENT_TYPE_PLACEHOLDER: 'Appointment type',
  EDIT_APPOINTMENT: 'Edit Appointment',
  DATE_OF_APPOINTMENT: 'DATE OF APPOINTMENT',
  ORDERS: 'Orders',
  NOTES_FOR_PATIENT: 'NOTES FOR PATIENT',
  PHYSICIAN_LABEL: 'PROVIDER / RESOURCE',
  UPDATE_APPOINTMENT_STATUS_SUCCESS_TOAST_TITLE:
    'Appointment status updated successfully',
  EXAMINATIONS: 'EXAMINATIONS',
  PARTNER_EXAMINATIONS: 'PARTNER EXAMINATIONS',
  TRANSVAGINAL_ULTRASONOGRAPHY: 'Transvaginal ultrasonography',
  SALINE_INFUSION_ULTRASONOGRAPHY: 'Saline infusion ultrasonography',
  HYSTEROSALPINGOGRAPHY: 'Hysterosalpingography',
  HYSTEROSALPINGOGRAM: 'Hysterosalpingogram',
  SONOHYSTEROGRAPHY: 'Sonohysterography',
  HYSTEROSALPINGO_CONTRAST_SONOGRAPHY: 'Hysterosalpingo-contrast sonography',
  LAP: 'Lap',
  ULTRASOUND_FEMALE: 'Ultrasound (female)',
  ULTRASOUND_MALE: 'Ultrasound (male)',
  VASOGRAPHY: 'Vasography',
  MAMMOGRAM: 'Mammogram',
  SPERM_ANALYSIS: 'Sperm Analysis',
  SPERM_ANALYSIS_IN_CYCLE: 'Semen Analysis - Cycle',
  SPERM_ANALYSIS_DIAGNOSTIC: 'Semen Analysis - Diagnostics',
  PATIENT_DETAILS: 'Patient Details',
  PARTNER_DETAILS: 'Partner Details',
  PATIENT_SINCE: 'Patient Since',
  NO_PARTNER: 'No partner',
  CARE_TEAM: 'Care Team',
  PATIENT_DETAILS_PAGE_TITLE: 'Embie Clinic | Patient Details',
  SEE_MORE_LABEL: 'See more',
  ADD_INTENDED_TREATMENT: 'Add intended treatment',
  ABOUT: 'About',
  EMAIL: 'Email',
  DATE_OF_BIRTH_LABEL: 'Date of Birth',
  SEX_AT_BIRTH: 'Sex at Birth',
  GENDER_TODAY: 'Gender Today',
  REASON_FOR_TREATMENT: 'Reason for Treatment',
  FERTILITY_TREATMENT: 'Fertility Treatment',
  GESTATIONAL_SURROGATE: 'Gestational Surrogate',
  INTENDED_PARENT: 'Intended Parent',
  OB_GYN: 'Ob/Gyn',
  IVF_USING_DONOR_EGG_OR_SPERM: 'IVF Using Donor Egg or Sperm',
  MALE_FERTILITY_EVALUATION: 'Male Fertility Evaluation',
  PATIENT_TYPE: 'Patient Type',
  PATIENT_SSN: 'SSN',
  LAST_NAME_AT_BIRTH: 'Last name at birth',
  NAME_TO_BE_CALLED: 'Name to be called',
  IS_DISABLED: 'Are you disabled?',
  LANGUAGE: 'Language',
  PRESCRIPTION: 'Prescription',
  MENSTRUAL_CYCLE_LENGTH: 'Choose AVG length',
  TF_OR_LESS: '25 Days or Less',
  TF_TO_TF_DAYS: '26 to 35 Days',
  TS_DAYS_AND_OVER: '36 Days and Over',
  IRREGULAR_CYCLE: 'Irregular Cycle',
  EDIT_IDENTIFICATION_INFO: 'Edit ID information',
  MAX_FILES_ALLOWED: 'Maximum number of files allowed',
  ERROR_UPLOADING_FILES: 'Error in uploading files',
  ID_NUMBER: 'Identification number',
  COUNTRY_OF_BIRTH: 'Country of birth',
  CHOOSE_DOCUMENT_TYPE: 'Choose document type',
  EDIT_DOCUMENT_NAME: 'Edit document name',
  EDIT_DOCUMENT: 'Edit document',
  ID_IMAGE: 'ID image',
  IDENTIFICATION_CONFIRMED_BY_STAFF_MEMBER:
    'Identification Confirmed by Staff Member',
  PASSPORT: 'Passport',
  NHS_NUMBER: 'NHS number',
  CHI: 'CHI',
  HCN: 'HCN',
  DRIVING_LICENCE: 'Driving Licence',
  BABY_HEIGHT: 'Baby height',
  UPDATE_CYCLE_SUCCESS_TOAST_TITLE: 'Cycle updated successfully',
  ABORTION: 'Abortion',
  BLIGHTED_OVUM: 'Blighted Ovum',
  COMPLETE_MISCARRIAGE: 'Complete Miscarriage',
  ECTOPIC: 'Ectopic',
  MISSED: 'Missed',
  MOLAR: 'Molar',
  TERMINATION_FOR_MEDICAL_REASONS: 'Termination for Medical Reasons',
  TUBAL_OR_ECTOPIC_PREGNANCY: 'Tubal or Ectopic Pregnancy',
  CERVIX_QUALITY: 'Cervix quality',
  MARRIED_TO_PARTNER_QUESTION:
    'If married or civil partnership, is it to the partner you will be treating with?',
  GET_PATIENT_ANSWER_BY_ID_ERROR_TOAST_TITLE: 'Error fetching patient answer',
  POST_ANSWER_FOR_QUESTION_SUCCESS_TOAST_TITLE: 'Category answers posted',
  ID_TYPE: 'Id type',
  BIRTH_COUNTRY: 'Country of Birth',
  ID_CONFIRMED_BY_CLINIC_STAFF: 'Confirmed by Clinic Staff',
  IDENTIFICATION: 'Identification',
  PRIMARY_CARE_PROVIDER: 'Primary Care Provider',
  DR_NAME: 'Dr Name',
  SAVE_CYCLE: 'Save cycle',
  EDIT_TREATMENT: 'Edit Treatment',
  NEXT_OF_KIN: 'EMERGENCY CONTACT',
  NAME: 'Name',
  RELATION: 'Relation',
  SEND_CONFIRMATION_EMAIL_SUCCESS_TOAST_TITLE:
    'Confirmation email sent successfully',
  KNOWN_FERTILITY_FACTORS: 'Known Fertility Factors',
  GENERAL_HISTORY: 'General history',
  PREVIOUS_CYCLE_NOTES: 'Previous Cycle notes',
  PRIMARY_DIAGNOSIS_AND_OBSERVATIONS: 'Primary diagnosis and observations',
  DATE_UPDATED: 'Date updated',
  PRIMARY_DIAGNOSIS: 'Primary diagnosis',
  PREVIOUS_PREGNANCIES: 'Previous pregnancies',
  LOW_MATURATION: 'Low Maturation',
  POOR_EGG_QUALITY: 'Poor Egg Quality',
  POOR_SPERM_QUALITY: 'Poor Sperm Quality',
  LOW_RESPONDER: 'Low Responder',
  CANCELLED_DUE_TO_LINING: 'Canceled due to Lining',
  CANCELED_DUE_TO_LOW_RESPONSE: 'Canceled due to low response',
  MALE_INFERTILITY: 'Male Infertility N46.9',
  UNEXPLAINED: 'Unexplained N97.9',
  HISTORY_OF_ENDOMETRIOSIS: 'History of endometriosis N80.9',
  TUBAL_LIGATION: 'Tubal ligation (not reversed) Z98.51',
  TUBAL_HYDROSALPINX: 'Tubal hydrosalpinx (in place) N70.91',
  OTHER_TUBAL_DISEASE: 'Other tubal disease N97.1',
  UTERINE_FACTOR: 'Uterine factor N97.2',
  OVULATION_DISORDERS: 'Ovulation disorders N97.0',
  FREEZE_ALL: 'Freeze-all (for fertility preservation, or not) Z31.62',
  INDICATION_FOR_USE_OF_GESTATIONAL_CARRIER:
    'Indication for use of gestational carrier Z31.7',
  RECURRENT_PREGNANCY_LOSS: 'Recurrent pregnancy loss N96',
  PCOS: 'PCOS',
  FIBROIDS: 'Fibroids',
  GENETIC: 'Genetic',
  AUTOIMMUNE: 'Autoimmune',
  MALE_FACTOR: 'Male Factor',
  MORPHOLOGY: 'Morphology',
  LOW_COUNT: 'Low Count',
  TUBAL: 'Tubal',
  CANCER_PATIENT: 'Cancer Patient',
  PREVIOUS_CYCLE_OBSERVATIONS: 'Previous cycle observations',
  CREATE_DIAGNOSIS_SUCCESS_TOAST_TITLE: 'Diagnosis added successfully',
  UPDATE_LAB_RESULT_SUCCESS_TOAST_TITLE: 'Lab result updated successfully',
  CREATE_LAB_RESULT_SUCCESS_TOAST_TITLE: 'Lab result created successfully',
  PLEASE_CHOOSE_TEST_NAME: 'Please choose test name',
  ADD_LAB_RESULT: 'Add lab result',
  PICK_SECTION: 'Pick section',
  DELIVERY_TIME_LABEL: 'Delivery time',
  MEDICATION_TYPE_LABEL: 'Medication type',
  ENTER_OBSERVATION: 'Enter observation',
  EDIT_EGG_RETRIEVAL_REPORT: 'Edit egg retrieval report',
  EDIT_EMBRYOLOGY_REPORT: 'Edit embryology report',
  EDIT_TRANSFER_REPORT: 'Edit transfer report',
  EDIT_IUI_REPORT: 'Edit IUI report',
  EDIT_SEMEN_ANALYSIS_REPORT: 'Edit semen analysis report',
  EDIT_FOLLICULAR_SCAN: 'Edit follicular scan report',
  UPDATE_EXAMINATION_SUCCESS_TOAST_TITLE:
    'Updated examination report successfully',
  CREATE_EXAMINATION_SUCCESS_TOAST_TITLE:
    'Created examination report successfully',
  ADD_PRESCRIPTION: 'Add prescription',
  CREATE_PRESCRIPTION_SUCCESS_TOAST_TITLE: 'Prescription added successfully',
  CREATE_AN_EXTERNAL_ORDER: 'Create an external order',
  PICK_PURPOSE: 'Pick purpose',
  PICK_BUNDLES: 'Pick bundles',
  PURPOSE: 'Purpose',
  EXAMS: 'Exams',
  RELATION_LABEL: 'Relation',
  RELATION_DEFAULT_OPTION: 'Please choose a relation',
  EDIT_NEXT_OF_KIN_INFO: 'Edit emergency contact information',
  MOTHER: 'Mother',
  FATHER: 'Father',
  BROTHER: 'Brother',
  SISTER: 'Sister',
  AUNT: 'Aunt',
  UNCLE: 'Uncle',
  SON: 'Son',
  DAUGHTER: 'Daughter',
  COUSIN: 'Cousin',
  INTERNAL_INBOX: 'Internal Inbox',
  UPDATE_PATIENT_FLAGS_SUCCESS_TOAST_TITLE: 'Flags updated successfully',
  APPOINTMENT_CANCELLED_SUCCESSFULLY: 'Appointment canceled successfully',
  COMPLETE_YOUR_PERSONAL_INFO: 'Complete your Personal info',
  ADD_FAMILY_DR_GENERAL_PRACTITIONER_INFORMATION:
    'Add Family Dr/General Practitioner information',
  ADD_FAMILY_DR_PRIMARY_CARE_PROVIDER_INFORMATION:
    'Add family Dr/Primary Care Provider information',
  ADD_NEXT_OF_KIN: 'Add emergency contact',
  VERIFY_YOUR_IDENTIFICATION: 'Verify your identification',
  COMPLETE_MEDICAL_HISTORY: 'Complete medical history',
  CREATE_ORDER_SUCCESS_TOAST_TITLE: 'Order created successfully',
  DATE_OF_ORDER: 'Date of order',
  NOT_AUTHORIZED: 'User not authorized',
  DATE_MUST_NOT_BE_IN_PAST: 'Date must not be in the past',
  CHOOSE_CYCLE: 'Choose cycle',
  NO_CYCLES: 'No cycles',
  EDIT_PRIMARY_CARE_PROVIDER_INFO: 'Edit Primary Care Provider information',
  REFERRING_DOCTOR_LABEL: 'Referring Doctor',
  REFERRING_DOCTOR_PLACEHOLDER: 'Tell us about your referring Doctor',
  PRIMARY_CARE_PROVIDER_NAME_LABEL: 'PRIMARY CARE PROVIDER NAME',
  SAVED_PRIMARY_CARE_PROVIDER_SUCCESS_TOAST_TITLE:
    'Patient Primary Care Provider updated successfuly',
  NAME_PLACEHOLDER: 'Full name',
  TERMS_OF_USE_AGREEMENT_TEXT:
    'By signing in to the Embie Clinic Portal, you acknowledge that you have read and agree to our',
  AND: 'and',
  PRIVACY_POLICY: 'Privacy Policy',
  CLINIC_PORTAL_TERMS_OF_SERVICES: 'Clinic Portal Terms of Services',
  CREATE_EMBRYOLOGY_SUCCESS_TOAST_TITLE: 'Report created successfully',
  NO_DATA: 'No data',
  NOT_BIOPSIED: 'Not biopsied',
  BIOPSIED: 'Biopsied',
  AFFECTED: 'Affected',
  NOT_AFFECTED: 'Not affected',
  MOSAIC: 'Mosaic',
  CHAOTIC: 'Chaotic',
  NO_RESULT: 'No Result',
  POTENTIAL_PATIENT: 'Potential Patient',
  INACTIVE_PATIENT: 'Inactive Patient',
  VIP: 'VIP',
  DEMO: 'Demo',
  GENDER_AT_BIRTH: 'Gender at birth',
  CRYO_FATE: 'Cryo',
  DISCARDED_FATE: 'Discarded',
  TRANSFERRED_FATE: 'Transferred',
  PLEASE_ENTER_EGGS_RETRIEVED: 'Please enter eggs retrieved count',
  CHOOSE_A_DOCUMENT: 'Choose a document',
  DOCUMENT_TYPE: 'Document Type',
  TEMPLATE_NAME: 'Template Name',
  CHOOSE_TEMPLATE_NAME: 'CHOOSE TEMPLATE',
  CONSENT: 'Consent',
  LABELS: 'Labels',
  GENERAL: 'General',
  RECORD: 'Record',
  MEDICAL_NOTES: 'Medical Notes',
  FOR_CLINIC_USE: 'For Clinic Use',
  EXTERNAL_LETTERS: 'External Letters',
  FOR_PATIENT_TO_COMPLETE: 'For Patient To Complete',
  TEMPLATE_NAME_REQUIRED: 'Template name is required',
  FETCH_DOCUMENT_TEMPLATES_SUCCESS_TOAST_TITLE:
    'Fetched document templates successfully',
  SELECT_CARE_TEAM_MEMBER: 'Select Member',
  UPDATE_PATIENT_CARE_TEAM_ERROR_TOAST_TITLE:
    'Error in adding patient care team member',
  SAVED_PATIENT_CARE_TEAM_SUCCESS_TOAST_TITLE:
    'Patient care team member added successfully',
  REMOVED_PATIENT_CARE_TEAM_SUCCESS_TOAST_TITLE:
    'Removed patient care team member successfully',
  TEMPLATE_NOT_FOUND: 'Template is not found',
  TEMPLATE_MISSING_NAME: 'Template name is missing',
  CREATE_PATIENT_DOCUMENT_SUCCESS_TOAST_TITLE:
    'Patient document saved successfully',
  ACTION_TITLE_DELETE_DOCUMENT: 'Deleting a Document',
  DOCUMENT_DELETED_SUCCESSFULLY: 'Document deleted successfully',
  SAVE_DOCUMENT: 'Save Document',
  THERE_IS_NO_ACTIVE_CYCLE_FOR_THIS_PATIENT_PLEASE_ADD_A_CYCLE_TO_GET_STARTED:
    'There is no active cycle for this patient. Please add a cycle to get started',
  MG: 'Mg',
  MCG: 'Mcg',
  GMS: 'Gms',
  SERVING: 'Serving',
  PARTNER_CONSENT_FORMS: 'Partner consent forms',
  PATIENT_FORMS: 'Patient Forms',
  PARTNER_FORMS: 'Partner Forms',
  GENERAL_DOCUMENTS: 'General Documents',
  PARTNER_GENERAL_DOCUMENTS: 'Partner General Documents',
  SAVED_PATIENT_DOCUMENT_SUCCESS_TOAST_TITLE:
    'Patient document updated successfully',
  ACTIVATE_CYCLE: 'Activate cycle',
  COMPLETE_CYCLE_INFO: 'Complete cycle info',
  PENDING_CYCLE_EXISTS_PROMPT:
    'This patient has an Intended Treatment already pending. Would you like to edit it and activate it now?',
  ACTIVATE_NOW: 'Activate now',
  CANCEL: 'Cancel',
  ACTIVE_CYCLE_EXIST_PROMPT:
    'It looks like there’s an active cycle for this patient. Please update the outcome report to close this cycle before you can begin another',
  PLEASE_CHOOSE_PATIENT: 'Please choose patient',
  THAW_REPORT: 'Thaw report',
  ADD_THAW_REPORT: 'Add thaw report',
  GAMETE_THAWED: 'Gamete thawed',
  PLEASE_CHOOSE_GAMETE: 'Please choose gamete',
  CHOOSE_AVAILABLE_GAMETE: 'Choose available gamete',
  EGG: 'Egg',
  PLEASE_CHOOSE_AN_AMOUNT_TO_THAW: 'Please choose an amout to thaw',
  MAX_GAMETE_YOU_CAN_CHOOSE_IS: 'Max gamete you can choose is',
  SEMEN: 'Semen',
  EMBRYOS_THAWED: 'Embryos thawed',
  GRADE_AT_FREEZE: 'Grade at freeze',
  GRADE_AT_THAW: 'Grade at thaw',
  GRADE_AT_ET: 'Grade at ET',
  BIOPSY: 'Biopsy',
  BIOPSY_RESULTS: 'Biopsy results',
  LUTEAL_SUPPORT: 'Luteal Support',
  EMAIL_ALREADY_IN_USE: 'Email is already in use',
  EGGS_COLLECTED: 'Eggs collected',
  EMBRYOS_TRANSFERRED: 'Embryos transferred',
  THREE: 'Three',
  NA: 'N/A',
  TRANSFER_REPORT: 'Transfer report',
  CHROMOSOMES_AFFECTED: 'Chromosomes affected',
  GENDER: 'Gender',
  SHIPPED_DATE: 'Shipped date',
  IMPORTED_DATE: 'Imported date',
  EASE: 'Ease',
  EDIT_CYCLE: 'Edit cycle',
  NOT_PROVIDED: 'Not provided',
  FOLLICULAR_EXAM: 'Follicular exam',
  IMAGING: 'Imaging',
  EXAM_TYPE: 'Exam type',
  EXAM_TYPE_REQUIRED: 'Please select an examination type',
  EXAM_REASON_REQUIRED: 'Please select an examination reason',
  FETAL_HEARTBEAT: 'Fetal heartbeat',
  EDIT_ULTRASOUND_EXAMINATION: 'Edit ultrasound examination',
  CHOOSE_FILES: 'Choose files',
  ERROR_STARTING_TELEHEALTH: 'Error starting telehealth session',
  OPEN_DOCUMENTS_EDITOR: 'Open Documents Editor',
  SIGN_PRESCRIPTIONS_DOCUMENT: 'Sign Prescriptions Document',
  NO_PRESCRIPTION_DOCUMENT_TO_DISPLAY:
    'There is no prescription document to display',
  NO_ORDERS_DOCUMENTS_TO_DISPLAY: 'There are no orders documents to display',
  SIGN_ORDERS_DOCUMENT: 'Sign Orders Document',
  COLLECTION_METHOD: 'Collection Method',
  COLLECTION_LOCATION: 'Collection Location',
  AT_CLINIC: 'At clinic',
  AT_PARTNER_CLINIC: 'At partner clinic',
  AT_HOME: 'At home',
  MICROFLUIDICS_CHIP: 'MICROFLUIDICS_CHIP',
  MASCERATION: 'MASCERATION',
  COLLAGENASE: 'Collagenase',
  SWIM_OUT: 'Swim Out',
  ZYMOT: 'Zymot',
  ANALYSIS_ONLY: 'Analysis Only',
  FINAL_SAMPLE_COMMENTS: 'Final sample comments',
  SPERM_PREP_FINISH_TIME: 'Sperm prep finish time',
  SPERM_PREP_START_TIME: 'Sperm prep start time',
  ADD_PHYSICAL_EXAMINATION_FEMALE: 'Add Physical Examination (Female)',
  EDIT_PHYSICAL_EXAMINATION_FEMALE: 'Edit Physical Examination (Female)',
  PHYSICAL_EXAMINATION_FEMALE: 'Physical Examination (Female)',
  SKIN_EXAM: 'Skin exam',
  HEART_RATE: 'Heart rate',
  BLOOD_PRESSURE: 'Blood pressure',
  RESPIRATORY_RATE: 'Respiratory rate',
  PULSE_OXIMETER: 'Pulse oximeter',
  GENERAL_PHYSICAL_EXAM: 'General physical exam',
  THYROID_EXAM: 'Thyroid exam',
  GENERAL_APPEARANCE: 'General appearance',
  EAR_NOSE_THROAT: 'Ear/nose/throat',
  EYES: 'Eyes',
  NODES: 'Nodes',
  HEART: 'Heart',
  LUNGS: 'Lungs',
  BREAST_EXAM: 'Breast exam',
  BOWEL_SOUNDS: 'Bowel sounds',
  ABDOMEN: 'Abdomen',
  LEGS: 'Legs',
  IF_ANY_ABNORMAL_FINDINGS_PLEASE_WRITE_MORE_ABOUT_IT:
    'If any abnormal findings, please write more about it',
  SPECULUM_EXAMINATION: 'Speculum examination',
  GENERAL_NOTES: 'General notes',
  BIMANUAL_PELVIC_EXAMINATION: 'Bimanual pelvic examination',
  HIRSUTISM: 'Hirsutism',
  ACNE: 'Acne',
  NO_ACNE: 'No acne',
  ANDROGENIC_ALOPECIA: 'Androgenic alopecia',
  ACANTHOSIS_NIGRICANS: 'Acanthosis nigricans',
  NO_HIRSUTISM: 'No hirsutism',
  NO_ANDROGENIC_ALOPECIA: 'No androgenicalopecia',
  NO_ACANTHOSIS_NIGRICANS: 'No acanthosis nigricans',
  ABNORMAL_SKIN_EXAM: 'Abnormal skin exam',
  NORMAL_SKIN_EXAM: 'Normal skin exam',
  ABNORMAL_THYROID_TEXTURE_AND_SIZE: 'Abnormal thyroid texture and size',
  NORMAL_THYROID_TEXTURE_AND_SIZE: 'Normal thyroid texture and size',
  PRESENCE_OF_NODULARITY: 'Presence of nodularity',
  NO_PRESENCE_OF_NODULARITY: 'No presence of nodularity',
  TENDERNESS: 'Tenderness',
  NO_TENDERNESS: 'No tenderness',
  CERVICAL_ADENOPATHY: 'Cervical adenopathy',
  NO_CERVICAL_ADENOPATHY: 'No cervical adenopathy',
  NORMAL_THYROID_EXAM: 'Normal thyroid exam',
  ABNORMAL_THYROID_EXAM: 'Abnormal thyroid exam',
  PALPABLE_TENDERNESS: 'Palpable tenderness',
  NO_PALPABLE_TENDERNESS: 'No palpable tenderness',
  MASSES: 'Masses',
  NO_MASSES: 'No masses',
  SKIN_CHANGES: 'Skin changes',
  NO_SKIN_CHANGES: 'No skin changes',
  EXPRESSED_OR_SPONTANEOUS: 'Expressed or spontaneous',
  NO_EXPRESSED_OR_SPONTANEOUS: 'No expressed or spontaneous',
  NIPPLE_DISCHARGE: 'Nipple discharge',
  NO_NIPPLE_DISCHARGE: 'No nipple discharge',
  NORMAL_BREAST_EXAM: 'Normal breast exam',
  ABNORMAL_BREAST_EXAM: 'Abnormal breast exam',
  DYSPAREUNIA: 'Dyspareunia',
  NO_DYSPAREUNIA: 'No dyspareunia',
  POSTCOITAL_SPOTTING: 'Postcoital spotting',
  NO_POSTCOITAL_SPOTTING: 'No postcoital spotting',
  VAGINAL_AND_CERVICAL_ABNORMALITIES: 'Vaginal and cervical abnormalities',
  NO_VAGINAL_AND_CERVICAL_ABNORMALITIES:
    'No vaginal and cervical abnormalities',
  LESIONS: 'Lesions',
  NO_LESIONS: 'No lesions',
  CERVICAL_POLYPS: 'Cervical polyps',
  NO_CERVICAL_POLYPS: 'No cervical polyps',
  NORMAL_SPECULUM_EXAM: 'Normal speculum exam',
  ABNORMAL_SPECULUM_EXAM: 'Abnormal speculum exam',
  RECTOVAGINAL_MASSES_OR_NODULARITY: 'Rectovaginal masses or nodularity',
  NO_RECTOVAGINAL_MASSES_OR_NODULARITY: 'No rectovaginal masses or nodularity',
  UTERINE_MASSES: 'Uterine masses',
  NO_UTERINE_MASSES: 'No uterine masses',
  OVARIAN_MASSES: 'Ovarian masses',
  NO_OVARIAN_MASSES: 'No ovarian masses',
  NORMAL_BIMANUAL_PELVIC_EXAM: 'Normal bimanual pelvic_exam',
  ABNORMAL_BIMANUAL_PELVIC_EXAM: 'Abnormal bimanual pelvic exam',
  CELSIUS: 'Celsius',
  FAHRENHEIT: 'Fahrenheit',
  ABNORMAL: 'Abnormal',
  PHYSICAL_EXAM_FEMALE: 'Physical exam female',
  BACK: 'Back',
  SIGNEE_AUTHORIZATION: 'Signee authorization',
  ANSWER_IS_REQUIRED: 'Answer is required',
  NO_ANSWER_OPTIONS: 'No answer options exist for this question',
  FINISH: 'Finish',
  MUST_BE_LESS_THAN_EGGS_MATURE: 'Must be less than eggs mature',
  MUST_BE_LESS_THAN_EGGS_RETRIEVED: 'Must be less than eggs retrieved',
  DATE_CANT_BE_IN_THE_FUTURE: "Date can't be in the future",
  DATE_CAN_ONLY_BE_IN_THE_FUTURE: 'Date can only be set to a future date',
  IUI_REPORT: 'IUI report',
  FLARE: 'Flare',
  EGG_AND_EMBRYO_OPTIONS: 'Egg and Embryo options',
  DONOR_EGG_THAW: 'Donor egg thaw',
  DONOR_EMBRYO_THAW: 'Donor embryo thaw',
  EMBRYO_ASSESSMENT: 'Embryo Assessment (AI)',
  MICROFLUIDICS_SPERM_CHIP: 'Microfluidics sperm chip',
  IMSI: 'IMSI',
  PIMSI: 'PIMSI',
  DONOR_SPERM_THAW: 'Donor sperm thaw',
  UPLOAD_A_DOCUMENT: 'Upload a document',
  CHOOSE_FROM_TEMPLATE: 'Choose from template',
  DOCUMENT_NAME: 'Document name',
  NAME_YOUR_DOCUMENT: 'Name your document',
  FILES_CHOSEN: 'Files chosen',
  DATE_OF_OUTCOME: 'Date of outcome',
  FOLLICULAR_EXAM_WITH_SAME_DATE_ERROR_TOAST_TITLE:
    'There is already a follicular exam with the same date for this cycle',
  CONTAINER_TYPE: 'Container type',
  COMPLETE_SAMPLE: 'Complete sample',
  STERILE_CONTAINER: 'Sterile Container',
  POSITIVE: 'Positive',
  NEGATIVE: 'Negative',
  STERILE_OVERNIGHT_COURIER: 'Sterile Overnight Courier',
  RESULTS_INITIAL_SAMPLE: 'Results (Initial sample)',
  NAVIGATION_QUICK_ADD: 'Quick Add',
  NAVIGATION_OVERVIEW: 'Overview',
  ACTIVITY_FEED: 'Activity',
  NAVIGATION_MESSAGES: 'Message',
  NAVIGATION_TELEHEALTH: 'Telehealth Video',
  NAVIGATION_CURRENT_CYCLE: 'Current Cycle',
  NAVIGATION_HISTORY: 'Medical History',
  NAVIGATION_DOCUMENTS: 'Documents',
  NAVIGATION_TASKS: 'Tasks',
  NAVIGATION_CONTACT: 'Contact Details',
  UPDATE_PRESCRIPTION_SUCCESS_TOAST_TITLE: 'Prescription updated successfully',
  TREATMENT_PREP: 'Treatment Prep',
  PRIMING: 'Priming',
  MAINTENANCE: 'Maintenance',
  STOP_MEDICATION: 'Stop Medication Starting Today',
  SYNC_CODES: 'Sync service lines based on appointment type',
  CANCEL_ACTION: 'Cancel Action',
  SHOW_ALL_APPOINTMENTS: 'Show All Appointments',
  EXTERNAL_LAB_RESULT: 'External Lab Result',
  DONE_BY: 'Done By',
  DONE_DATE: 'Done Date',
  REVIEW_ALL_PATIENT_ENTERED_ONBOARDING_INFORMATION:
    'Review all patient entered onboarding information',
  ADDED_DATE: 'Added Date',
  EDIT: 'Edit',
  MENU: 'Menu',
  PRESCRIPTION_GAP_ERROR: 'Please create a new prescription',
  NEW_TASKS_TITLE: 'New tasks',
  CHOOSE_LAB: 'Choose lab',
  LAB: 'Lab',
  BILL_TO: 'Bill to',
  THERE_IS_ALREADY_AN_ONGOING_CYCLE_MESSAGE:
    'There is already an ongoing cycle, please close it before updating this one to ongoing.',
  OUTCOME_DATE_MUST_BE_BEFORE_TODAY: 'Outcome date must be before today',
  FINAL_BLASTOCYST: 'Final Blastocyst',
  RESULT_REQUIRED: 'Result is required',
  DELETE_RESULT: 'Delete Result',
  DELETE_DOCUMENT_TITLE: 'Delete Document',
  DELETE_TASK_TITLE: 'Delete Task',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_DOCUMENT:
    'Are you sure you want to delete this Document?',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_TASK:
    'Are you sure you want to delete this Task?',
  ACTION_TITLE_DELETE_TASK: 'Deleting a Task',
  DELETE_TASK_SUCCESS_TOAST_TITLE: 'Task deleted successfully',
  DELETE_LAB_RESULT_SUCCESS_TOAST_TITLE: 'Lab result deleted successfully',
  ARE_YOU_SURE_YOU_WANT_TO_PROCEED: 'Are you sure you want to Proceed?',
  CONFIRM_STOP_MEDICATION_MESSAGE:
    'Taking this step will delete the medication completely from the treatment in both the chart and for the patient.',
  YES_STOP_THE_MEDICATION: 'Yes, stop the medication',
  NO_I_DONT_WANT_TO_PROCEED: 'No, I dont want to proceed',
  VALUE_MUST_BE_POSITIVE: 'Value must be positive',
  THIS_CYCLE_IS_PENDING: 'This cycle is pending',
  FIRST_BETA_VALUE: 'Beta #1',
  SECOND_BETA_VALUE: 'Beta #2',
  THERE_IS_NO_ORDERS_DOCUMENT_TO_DISPLAY:
    'There is no orders document to display',
  YOUR_DOCUMENT_IS_SAVING: 'Your document is saving...',
  PHONE_NUMBER_INVALID: 'Phone number is invalid',
  EMAIL_INVALID: 'Email is invalid',
  DATE_OF_BIRTH_INVALID: 'Date of birth is invalid',
  CHOOSE_A_TEMPLATE_TO_EDIT: 'Choose a template to edit',
  RESEND_WELCOME_EMAIL: 'Resend welcome Email',
  RESEND_WELCOME_EMAIL_REQUEST:
    'Which patient would you like to resend the welcome email to?',
  RESEND_PATIENT_WELCOME_EMAIL_SUCCESS_TOAST_TITLE:
    'Welcome email sent successfully',
  MEDICATION_TYPE_REQUIRED: 'Medication type is required',
  CLICK: 'Click',
  CONFIRM_START_DATE: 'Confirm Start Date',
  LAB_REQUIRED: 'Lab is required',
  CYCLE_CLOSE_DATE: 'Cycle close date',
  CYCLE_OUTCOME_HEADER: 'Cycle outcome',
  IS_NON_COMPLIANT_ET_QUESTION: 'Is this a non-compliant ET?',
  REASON_FOR_NON_COMPLIANT_ET: 'Reason for non-compliant ET',
  TYPE_IN_THE_REASON: 'Type in the reason',
  APPOINTMENT_DATE: 'Appointment date',
  SCAN_AND_BLOODS_REVIEWED_BY_CLINICIAN:
    'Scan and bloods reviewed by clinician',
  CONSENTS_REVIEWED_AND_VALID: 'Consents reviewed and valid',
  VIROLOGIES_REVIEWED_AND_VALID: 'Virologies reviewed and valid',
  ITT_SENT_TO_HFEA: 'ITT sent to HFEA',
  CONFIRM_PATIENT_RECEIVED_MEDICATION: 'Confirm patient received medication',
  PATIENT_AWARE_OF_MEDICATION_ADMINISTRATION:
    'Patient aware of medication administration',
  CYCLE_SUMMARY_WITH_MEDICATION_REGIME: 'Cycle summary with medication regime',
  CONFIRMED_NEXT_STEPS_WITH_PATIENT: 'Confirmed next steps with patient',
  USS_COMPLETE_DOCUMENTED: 'USS Complete documented',
  SA_REVIEWED: 'SA reviewed.',
  REFERRALS_MADE_IF_NECESSARY: 'Referrals made if necessary.',
  PRE_TREATMENT_BLOODS_TAKEN: 'Pre-treatment bloods taken.',
  FOLLOW_UP_BOOKED: 'Follow-up booked.',
  OUTCOME_DISCUSSED: 'Outcome discussed',
  AWARE_OF_CONTACT_DETAILS: 'Aware of contact details',
  DISCHARGE_SUMMARY_SENT: 'Discharge summary sent',
  AWARE_TO_CONTACT_CLINIC_WITH_BIRTH_DETAILS:
    'Aware to contact clinic with birth details',
  OPERATIVE_PAPERWORK_COMPLETE_AND_UPLOADED:
    'Operative paperwork complete and uploaded',
  DISCUSSED_NEXT_STEPS_AND_SAFETY_NETTING_ADVICE:
    'Discussed next steps and safety netting advice',
  FOLLOW_UP_OR_ET_BOOKED: 'Follow up or ET booked',
  IF_APPLICABLE_CONFIRMED_PATIENT_HAS_MEDICATION_AND_AWARE_OF_ADMINISTRATION:
    'If applicable, confirmed patient has medication and aware of administration.',
  '1_DAY_POST_EC_FOLLOW_UP_OHSS_REVIEW_ALREADY_MADE':
    '1 Day Post EC: follow up/OHSS review (already made)',
  '7_DAYS_POST_EC_FOLLOW_UP_OHSS_REVIEW_ALREADY_MADE':
    '7 Days Post EC: follow up/OHSS review (already made)',
  CYCLE_SUMMARY_UPDATED: 'Cycle summary updated',
  PROCEDURE_COMPLETE: 'Procedure complete',
  DISCHARGE_INFORMATION_GIVEN_AND_DISCUSSED:
    'Discharge information given and discussed',
  OHSS_DISCUSSED: 'OHSS discussed',
  SAFETY_NETTING_ADVICE_GIVEN: 'Safety netting advice given',
  CONFIRMED_SUFFICIENT_LUTEAL_SUPPORT_AND_CONFIDENT_WITH_ADMINISTRATION:
    'Confirmed sufficient luteal support and confident with administration',
  REVIEWED_FOR_CLEXANE_STEROIDS: 'Reviewed for clexane/steroids',
  PREGNANCY_TEST_DATE_BOOKED_BHCG_UPT: 'Pregnancy test date booked (BHCG/UPT)',
  SUBMIT_PRISM_LAB_FORM_IF_FET: 'Submit PRISM lab form (if FET)',
  UPDATE_PATIENT_WITH_ICSI_RESULT_IF_APPLICABLE:
    'Update patient with ICSI result (if applicable)',
  UPDATE_PATIENT_WITH_FERT_RESULT_AND_SEND_CHLOE_LINK_X:
    'Update patient with fert result and send CHLOE link - x',
  CONFIRM_TRANSFER_DATE_AND_TIME_IF_APPLICABLE:
    'Confirm transfer date and time (if applicable)',
  UPDATE_PATIENT_WITH_BLAST_RESULT_ON_DAY_5:
    'Update patient with blast result on day 5',
  UPDATE_PATIENT_WITH_BLAST_RESULT_ON_DAY_6:
    'Update patient with blast result on day 6',
  SUBMIT_PRISM_LAB_FORM: 'Submit PRISM lab form',
  TESTS_REVIEWED_PATIENT_INFORMED_OF_RESULTS:
    'Tests reviewed, patient informed of results.',
  TREATMENT_PLAN_CONFIRMED: 'Treatment plan confirmed.',
  REVIEWED_ANY_CHANGES_IN_MEDICAL_HISTORY:
    'Reviewed any changes in medical history.',
  NEXT_STEPS_BOOKED_NURSE_CONSULTATION_REFERRAL_ETC:
    'Next steps booked (Nurse Consultation, Referral etc)',
  CONFIRMED_SUFFICIENT_SUPPLY_OF_MEDICATION:
    'Confirmed sufficient supply of medication',
  MEDICAL_FERTILITY_HISTORY_REVIEWED_FOR_PATIENT_AND_PARTNER:
    'Medical/Fertility History Reviewed for patient and partner',
  PROTOCOL_AND_MEDICATION_DOSE_CONFIRMED_AND_DOCUMENTED:
    'Protocol and medication dose confirmed and documented.',
  WOC_AND_CD_SIGNED: 'WOC and CD signed.',
  COUNSELLING_OFFERED: 'Counselling offered.',
  PRE_TREATMENT_TESTS_BOOKED_DIAGNOSTICS_SA_BLOODS_VIROLOGIES:
    'Pre-treatment tests booked (diagnostics, SA, bloods, virologies)',
  ID_CONFIRMED: 'ID confirmed',
  MEDICAL_HISTORY_ALLERGIES_REVIEWED: 'Medical history/allergies reviewed',
  PROTOCOL_DISCUSSED: 'Protocol discussed',
  INJECTION_TEACH_PROVIDED: 'Injection teach provided',
  PRIMING_OFFERED_AND_SCAN_DATES_CONFIRMED:
    'Priming offered and scan dates confirmed',
  VIROLOGYS_REVIEWED_AND_VALID: 'Virology’s reviewed and valid',
  PRESCRIPTION_CREATED_AND_SENT_TO_STORK:
    'Prescription created and sent to STORK',
  CONSENT_FORMS_ASSIGNED_AND_ADVISED_GIVEN:
    'Consent forms assigned and advised given',
  DISCUSSED_POST_HUMOUS_USE_OF_GAMETES_WT_MT:
    'Discussed post-humous use of gametes (WT/MT)',
  WOC_SIGNED_BY_CLINICIAN: 'WOC signed by clinician',
  ANAESTHETIC_REVIEW_REQUIRED_Y_N: 'Anaesthetic review required ? (Y/N)',
  RISK_OF_OHSS_CYCLE_CANCELLATION_POOR_RESPONSE_DISCUSSED:
    'Risk of OHSS, cycle cancelation, poor response discussed',
  AWARE_OF_AVOID_STRENUOUS_EXERCISE_UNPROTECTED_INTERCOURSE_AND_ALCOHOL_DURING_TREATMENT:
    'Aware of avoid strenuous exercise, unprotected intercourse and alcohol during treatment',
  RESULT_PROVIDED: 'Result provided',
  NEXT_STEPS_DISCUSSED_AND_ARRANGED: 'Next steps discussed and arranged',
  COUNSELLING_OFFERED_IF_APPLICABLE: 'Counselling offered if applicable',
  PRESCRIPTION_PROVIDED_IF_APPLICABLE: 'Prescription provided if applicable',
  NORMAL_SYMPTOMS_DISCUSSED: 'Normal symptoms discussed',
  REFERRAL_TO_PCP_EPU_ADVISED: 'Referral to PCP/EPU advised',
  EARLY_OUTCOME_SENT_TO_HFEA: 'Early outcome sent to HFEA',
  OFFERED_HARMONY_NIPT: 'Offered Harmony/NIPT',
  RED_FLAG_CREATED_IF_NEGATIVE_OUTCOME: 'Red flag created if negative outcome',
  ID_CONFIRMED_AND_VERIFIED: 'ID Confirmed and verified',
  PRISM_REGISTRATION_SUBMITTED_TO_HFEA: 'PRISM Registration submitted to HFEA',
  WOC_AND_CD_FORMS_ASSIGNED: 'WOC and CD Forms assigned',
  PATIENTS_NEXT_APPOINTMENT_BOOKED: 'Patients next appointment booked',
  CYCLE_SUMMARY_UPDATED_WITH_TRIGGER_MEDICATION_AND_TIMING:
    'Cycle summary updated with trigger medication and timing',
  EC_BOOKED_IN_SCHEDULE: 'EC booked in schedule',
  CONFIRMED_TRIGGER_MEDICATION_ADMINISTRATION_AND_TECHNIQUE:
    'Confirmed trigger medication administration and technique',
  AWARE_OF_FASTING_AND_ABSTINENCE_INSTRUCTIONS:
    'Aware of fasting and abstinence instructions',
  AWARE_TO_ATTEND_WITH_NO_MAKEUP_JEWELLERY_PERFUME:
    'Aware to attend with no makeup, jewellery, perfume',
  REVIEWED_ANAESTHETIC_RISK: 'Reviewed anaesthetic risk',
  ALL_CONSENTS_AND_VIROLOGIES_IN_PLACE: 'All consents and virologies in place',
  ADD_AVATAR_TO_YOUR_PROFILE: 'Add avatar to your profile',
  GET_INBOX_ERROR_TOAST_TITLE: 'Error getting inbox',
  ADD_TASK_LIST: 'Add task list',
  APPOINTMENTS: 'Appointments',
  PHYSICIAN: 'Provider',
  NO_PRIMARY_PHYSICIAN: 'No Primary Provider',
  EXTERNAL_ORDER_FORM_SUB_HEADER:
    'Use this order form when placing orders outside of an in-clinic encounter. For example, when monitoring remotely or testing at a Labcorp location.',
  PROCEDURE_INSTRUCTIONS: 'Procedure Instructions',
  ENTER_DIAGNOSTIC_CODE: 'Enter diagnostic code',
  SET_PROCEDURE_ORDERS: 'Set procedure orders',
  SET_LAB_ORDERS: 'Set lab orders',
  LAB_INSTRUCTIONS: 'Lab Instructions',
  ROUTINE: 'Routine',
  ASAP: 'ASAP',
  URGENT: 'Urgent',
  STAT: 'Stat',
  SIGN_PROCEDURE_ORDER: 'Sign Procedure order',
  SIGN_LAB_ORDER: 'Sign Lab order',
  NO_LAB_ORDER_TO_DISPLAY: 'There are no lab orders to display',
  NO_PROCEDURE_ORDER_TO_DISPLAY: 'There are no procedure orders to display',
  EXTERNAL_ORDER_HAS_BEEN_CREATED: 'External order has been created!',
  PLEASE_SIGN_THE_ORDERS_NOW: 'Please sign the orders now',
  PICK_A_PURPOSE: 'Pick a purpose',
  PURPOSE_REQUIRED: 'Purpose is required',
  PERFORMING_LAB_REQUIRED: 'Performing lab is required',
  BILL_TO_REQUIRED: 'Bill to is required',
  URGENT_REQUIRED: 'Urgency is required',
  FASTING_REQUIRED: 'Is fasting is required',
  IS_FASTING: 'Is fasting',
  URGENCY: 'Urgency',
  CHOOSE_URGENCY: 'Choose urgency',
  WELCOME_CONVERSATION: 'Welcome Conversation',
  FERTILITY_DISCOVERY_FOLLOW_UP: 'Fertility Discovery Follow-Up',
  REI_CONSULTATION: 'REI Consultation',
  RN_HEALTHCARE_PROFESSIONAL_CONSULT: 'RN/Healthcare Professional Consult',
  SURGICAL_CONSULT: 'Surgical Consult',
  FINANCIAL_CONSULTATION: 'Financial Consultation',
  MALE_PROCEDURES: 'Male Procedures',
  MICRO_TESE: 'Micro TESE (off-site)',
  SPERM_CRYO_FREEZE: 'Sperm Cryo (Freeze)',
  CC_PROCESSING_FEE: 'CC Processing Fee',
  BILLING_CHARGES: 'Billing Charges',
  WITH_REI: 'With REI',
  WITH_FINANCIAL_COUNSELOR: 'With Financial Counselor',
  GESTATIONAL_CARRIER_CONSULTATION: 'Gestational Carrier Consultation',
  DONOR_SCREENING: 'Donor Screening',
  CONSENTS: 'Consents',
  ERROR_FALGGING_ACTIVITY: 'Error flagging activity',
  CHOOSE_LAB_IN_ORDER_TO_SELECT_LABS:
    'Choose a lab in order to select lab orders',
  SECONDARY_INSURANCE: 'Secondary Insurance',
  TERTIARY_INSURANCE: 'Tertiary Insurance',
  DOES_PATIENT_HAVE_INSURANCE: 'Does patient have insurance?',
  RELATION_TO_INSURED: 'Relationship To Insured',
  IS_THIS_YOUR_REGISTER_PARTNER:
    'Is This Your Registered Partner For Treatment?',
  INSURANCE_TYPE: 'Insurance Type',
  INSURANCE_ID: 'Insurance ID',
  INSURANCE_POLICY_OR_FECA_NUMBER: 'Insurance Policy Group Or FECA Number',
  EMPLOYER_NAME: "Employer's Name",
  IF_INSURED_IS_NOT_SELF_OR_PARTNER:
    'If The Insured Is Not Self Or The Registered Partner:',
  INSURED_NAME: "Insured's Name",
  INSURED_FIRST_NAME: "Policy holder's first name",
  INSURED_LAST_NAME: "Policy holder's last name",
  INSURED_GENDER: "Insured's Gender",
  INSURED_DBO: "Insured's Date Of Birth",
  INSURED_ADDRESS: "Insured's Address",
  INSURANCE_INFORMATION: 'Insurance Information',
  RELATIONSHIP_TO_INSURED_PLACEHOLDER:
    'Please choose a relationship to insured',
  INSURANCE_PRIMARY_PLACEHOLDER: 'Please choose primary insurance',
  INSURANCE_TYPE_PLACEHOLDER: 'Please choose insurance type',
  INSURANCE_NAME_PLACEHOLDER: 'Please choose insurance plan',
  INSURANCE_NAME: 'Insurance Name',
  INSURANCE_POLICY_OR_FECA_NUMBER_PLACEHOLDER:
    'Enter your policy group number or FECA number',
  INSURANCE_ID_REQUIRED:
    'Enter your insurance ID as shown on insured’s ID card',
  SELF: 'Self',
  SPOUSE: 'Spouse',
  INSURANCE_ID_PLACEHOLDER: 'Number as shown on insured’s ID card',
  EMPLOYERS_NAME_PLACEHOLDER: "Enter the employer's name of the insured",
  NAME_OF_INSURANCE_POLICY_HOLDER:
    'Name of the person who holds the insurance policy',
  FRONT_OF_CARD: 'Front Of Card',
  BACK_OF_CARD: 'Back Of Card',
  DOES_NOT_HAVE_INSURANCE_COVERAGE: 'Does not have Insurance coverage',
  INSURANCE_PLAN_NAME_PLACEHOLDER:
    'Enter the name of the insurance plan or program',
  INSURED_NAME_REQUIRED: "Please enter insured's name",
  INSURED_DOB_REQUIRED: "Please enter insured's date of birth",
  INSURED_ADDRESS_REQUIRED: "Please enter insured's address",
  INSURED_CITY_REQUIRED: "Please enter insured's city",
  INSURED_STATE_REQUIRED: "Please enter insured's state",
  INSURED_STATE_INVALID: 'Please enter a valid US state code',
  INSURED_ZIP_REQUIRED: "Please enter insured's zip",
  INSURED_COUNTRY_REQUIRED: "Please enter insured's country",
  INSURED_GENDER_PLACEHOLDER: "Please enter insured's gender",
  INSURED_GENDER_REQUIRED: "Please enter insured's gender",
  UPLOAD_INSURANCE_CARD: 'Upload Insurance Card',
  CYCLE_ID: 'Cycle Id',
  PRESCRIBING_PROVIDER: 'Prescribing Provider',
  ROUTE: 'Route',
  FREQUENCY: 'Frequency',
  FREQUENCY_REQUIRED: 'Please choose frequency',
  OTHER_INSTRUCTIONS: 'Other Instructions',
  TAKE_AT: 'Take at',
  MEDICATION_UNIT_AMPUL: 'Ampul',
  MEDICATION_UNIT_APPLICATOR: 'Applicator',
  MEDICATION_UNIT_AS_DIRECTED: 'As directed',
  MEDICATION_UNIT_BOTTLE: 'Bottle',
  MEDICATION_UNIT_BOX: 'Box',
  MEDICATION_UNIT_CAPLET: 'Caplet',
  MEDICATION_UNIT_CAPSULE: 'Capsule',
  MEDICATION_UNIT_CLICKS: 'Clicks',
  MEDICATION_UNIT_DEVICE: 'Device',
  MEDICATION_UNIT_DROP: 'Drop',
  MEDICATION_UNIT_EACH: 'Each',
  MEDICATION_UNIT_FOAM: 'Foam',
  MEDICATION_UNIT_GELCAP: 'Gelcap',
  MEDICATION_UNIT_GRAM: 'Gram',
  MEDICATION_UNIT_INSERT: 'Insert',
  MEDICATION_UNIT_KIT: 'Kit',
  MEDICATION_UNIT_LANCET: 'Lancet',
  MEDICATION_UNIT_MEQ: 'meq',
  MEDICATION_UNIT_NEEDLE: 'Needle',
  MEDICATION_UNIT_PACK: 'Pack',
  MEDICATION_UNIT_PACKET: 'Packet',
  MEDICATION_UNIT_PATCH: 'Patch',
  MEDICATION_UNIT_PEN: 'Pen',
  MEDICATION_UNIT_PEN_INJECTOR: 'Pen injector',
  MEDICATION_UNIT_PEN_NEEDLE: 'Pen needle',
  MEDICATION_UNIT_SUPPOSITORY: 'Suppository',
  MEDICATION_UNIT_SYRINGE: 'Syringe',
  MEDICATION_UNIT_TABLET: 'Tablet',
  MEDICATION_UNIT_UNIT: 'Unit',
  MEDICATION_UNIT_VIAL: 'Vial',
  MEDICATION_UNIT_MCG: 'mcg',
  MEDICATION_UNIT_MG: 'mg',
  MEDICATION_UNIT_ML: 'ml',
  MEDICATION_UNIT_TUBE: 'Tube',
  MEDICATION_INTAKE_METHOD_A_SMALL_AMOUNT: 'A small amount',
  MEDICATION_INTAKE_METHOD_AS_DIRECTED: 'As directed',
  MEDICATION_INTAKE_METHOD_BY_MOUTH: 'By mouth',
  MEDICATION_INTAKE_METHOD_DISSOLVED_IN_WATER: 'Dissolved in water',
  MEDICATION_INTAKE_METHOD_INTRAMUSCULARLY: 'Intramuscularly',
  MEDICATION_INTAKE_METHOD_PATCH: 'Patch',
  MEDICATION_INTAKE_METHOD_SUBCUTANEOUSLY: 'Subcutaneously',
  MEDICATION_INTAKE_METHOD_VAGINALLY: 'Vaginally',
  MEDICATION_INTAKE_METHOD_VIA_APPLICATOR: 'Via applicator',
  OTHER_INSTRUCTIONS_AS_DIRECTED: 'As directed',
  OTHER_INSTRUCTIONS_WITH_MEALS: 'With meals',
  OTHER_INSTRUCTIONS_BETWEEN_MEALS: 'Between meals',
  OTHER_INSTRUCTIONS_AFTER_MEALS: 'After meals',
  OTHER_INSTRUCTIONS_ONE_HOUR_BEFORE_MEALS: 'One hour before meals',
  OTHER_INSTRUCTIONS_WITH_A_GLASS_OF_WATER: 'With a glass of water',
  OTHER_INSTRUCTIONS_BEFORE_EXERCISE: 'Before exercise',
  PACKAGE_TYPE: 'Package type',
  PRESCRIPTION_HAS_BEEN_SENT_TO_DR_FIRST:
    'The prescription has been sent to Dr First',
  TO_DIGITALLY_SING_THE_PRESCRIPTION_CLICK:
    'To digitally sign the prescription click below.',
  SIGN_NOW: 'Sign Now',
  FREQUENCY_OPTION_TAKE_DAILY: 'Take Daily',
  FREQUENCY_OPTION_EVERY_OTHER_DAY: 'Every Other Day',
  FREQUENCY_OPTION_EVERY_THREE_DAYS: 'Every 3 Days',
  EFAX_DOCUMENT_TITLE: 'eFAX A Document',
  SEND_TO_NEW_CONTENT: 'Send to new contact',
  COMPANY: 'Company',
  SEND_COVER_LETTER: 'send cover letter',
  SUBJECT: 'Subject',
  MESSAGE: 'Message',
  PHONE_NUMBER: 'Phone Number',
  SEND_EFAX_SUCCESS_TOAST_TITLE: 'Send eFax successfully',
  VIEW_SIGN_DOCUMENT_TITLE: 'View/Sign',
  EDIT_NAME: 'Edit Name',
  VIEW_DOCUMENT_TITLE: 'View',
  FAX_NUMBER: 'Fax Number',
  FAX_NUMBER_INVALID: 'Fax number is invalid',
  SELECT_PRIMARY_INSURANCE_LABEL: 'Select primary insurance',
  INSURANCE_PRIMARY_REQUIRED: 'Please select primary insurance',
  SEMEN_ANALYSIS_REPORT_NOTE:
    'Results are based on the WHO laboratory manual for the examination and processing of human semen Sixth edition.',
  VIEW_ALL: 'View All',
  MUST_BE_NUMBER: 'Must be a number',
  NOTE_IS_LOCKED: 'Note is Locked',
  NOTE_IS_UNLOCKED: 'Note is Unlocked',
  NO_DOCUMENTS: 'No documents found'
};

export default enPatientsTranslations;
