import { Breakpoint } from '@mui/material';
import type { Appointment } from './appointment';
import { CreatePatientDocumentPayload } from './documents';
import { YesOrNo } from './global';
import { ArrowDirection, PersonSex } from './patient';
import {
  MedicalProtocolForm,
  Prescription,
  PrescriptionRxForm,
  PrescriptionsRx
} from './prescription';
import { StaffMember } from './staff';
import { PatientTask } from './task';
import { Fate, GeneticStatus } from './eggAndEmbryo';
import { CycleServer } from './patient-server';

export enum PreviousCycleObservations {
  LOW_FERTILIZATION = 'Low fertilization',
  LOW_MATURATION = 'Low Maturation',
  POOR_EGG_QUALITY = 'Poor Egg Quality',
  POOR_SPERM_QUALITY = 'Poor Sperm Quality',
  LOW_RESPONDER = 'Low Responder',
  CANCELLED_DUE_TO_LINING = 'Cancelled due to Lining',
  CANCELED_DUE_TO_LOW_RESPONSE = 'Canceled due to low response'
}

export interface CycleGameteCommentsUpdate {
  cycleId: string;
  patientId: string;
  commentsToCreate: {
    value: string;
    type: Gamete;
  }[];
  commentsToDelete?: string[];
}

export interface CycleGameteComment extends CycleNote {
  type: Gamete;
  updatedAt?: Date;
  isDeleted?: boolean;
}

export interface ValueType {
  value: number;
  arrowDirection: ArrowDirection;
  isWithPercentage: boolean;
}

export interface KpiCycleStatus {
  ivf: ValueType;
  ivfPlusFresh: ValueType;
  fet: ValueType;
  eggFreezing: ValueType;
  iui: ValueType;
  coo?: ValueType;
}

export interface PregnancyRate {
  ivfPlusFresh: ValueType;
  fet: ValueType;
  iui: ValueType;
  coo: ValueType;
}

export interface FertilizationRate {
  icsi: ValueType;
  conventional: ValueType;
}

export enum CycleLocation {
  THIS_CLINIC = 'this clinic',
  PARTNER_CLINIC = 'partner clinic'
}

export enum CycleStatus {
  PENDING = 'Pending',
  ONGOING = 'Ongoing',
  COMPLETE = 'Complete',
  CANCELED_LOW_RESPONSE = 'Canceled - Low Response',
  CANCELED_LINING = 'Canceled - Lining',
  CANCELED_FLUID = 'Canceled - Fluid',
  CANCELED_HIGH_RISK = 'Canceled - High Risk',
  CANCELED_OTHER = 'Canceled Other'
}

export const finishCycleStatus = [
  CycleStatus.CANCELED_FLUID,
  CycleStatus.CANCELED_HIGH_RISK,
  CycleStatus.CANCELED_LINING,
  CycleStatus.CANCELED_LOW_RESPONSE,
  CycleStatus.CANCELED_OTHER,
  CycleStatus.COMPLETE
];

export enum EggSource {
  OWN = 'Own',
  DONOR = 'Donor Egg',
  SHARED_MOTHERHOOD = 'Shared Motherhood',
  DONOR_GAMETE_MATCHING = 'Donor Gamete Matching'
}

export enum SpermSource {
  OWN = 'Own',
  DONOR = 'Donor Sperm',
  PARTNER_FRESH = 'Partner / Fresh',
  PARTNER_FROZEN = 'Partner / Frozen',
  NA = 'N/A'
}

export enum TreatmentTypes {
  OVULATION_INDUCTION = 'Ovulation Induction',
  IUI = 'IUI',
  EGG_FREEZING = 'Egg Freezing',
  IVF_ET = 'IVF + ET',
  IVF = 'IVF',
  FET = 'FET',
  MOCK_CYCLE_ERA = 'Mock Cycle/ERA'
}

export enum ProtocolTypes {
  ANTAGONIST = 'Antagonist',
  LONG_AGONIST = 'Long Agonist',
  MILD_MINI_IVF = 'Mild/Mini IVF',
  NATURAL = 'Natural',
  MODIFIED_NATURAL = 'Modified Natural',
  FLARE = 'Flare',
  MEDICATED = 'Medicated',
  'N/A' = 'N/A'
}

export enum ProtocolTemplates {
  GONAL_150 = 'Antagonist Gonal 150',
  LONG_AGONIST_30_DAY = 'Long Agonist Lupron 30 day',
  MILD_MINI_IVF = 'Mild/Mini IVF',
  NATURAL = 'Natural',
  MODIFIED_NATURAL = 'Modified Natural'
}

export enum PregnancyStatus {
  ONGOING = 'Ongoing',
  ABORTION = 'Abortion',
  BLIGHTED_OVUM = 'Blighted Ovum',
  CHEMICAL = 'Chemical',
  COMPLETE_MISCARRIAGE = 'Complete Miscarriage',
  ECTOPIC = 'Ectopic',
  MISSED = 'Missed',
  MISSED_MISCARRIAGE = 'Missed Miscarriage',
  LIVE_BIRTH = 'Live Birth',
  MOLAR = 'Molar',
  STILL_BIRTH = 'Still Birth',
  TERMINATION_FOR_MEDICAL_REASONS = 'Termination for Medical Reasons',
  MISCARRIAGE = 'Miscarriage',
  TUBAL_OR_ECTOPIC_PREGNANCY = 'Tubal or Ectopic Pregnancy'
}

export enum CycleOutcome {
  TBD = 'TBD',
  PREGNANT = 'Pregnant',
  NOT_PREGNANT = 'Not Pregnant'
}

export enum EggOptions {
  ASSISTED_HATCHING = 'Assisted Hatching',
  ARTIFICIAL_OOCYTE_ACTIVATION = 'Artificial Oocyte Activation',
  OOCYTE_ASSESSMENT = 'Oocyte Assessment (AI)',
  EGG_OR_EMBRYO_THAW = 'Egg or embryo Thaw',
  POLAR_BODY_BIOPSY = 'Polar body biopsy',
  DONOR_EGG_THAW = 'Donor egg thaw',
  DONOR_EMBRYO_THAW = 'Donor embryo thaw',
  EMBRYO_ASSESSMENT = 'Embryo Assessment (AI)'
}

export enum SpermOptions {
  SPERM_ZYMOT = 'Sperm Zymot',
  SPERM_MOTILITY_ENHANCER = 'Sperm Motility Enhancer',
  ICSI = 'ICSI',
  PIMSI_PICSI_IMSI = 'PIMSI (pICSI+IMSI)',
  EMBRYOGEN = 'Embryogen',
  DONOR_SPERM = 'Donor Sperm',
  MICROFLUIDICS_SPERM_CHIP = 'Microfluidics sperm chip',
  IMSI = 'IMSI',
  PIMSI = 'PIMSI',
  DONOR_SPERM_THAW = 'Donor sperm thaw'
}

export enum UterusOptions {
  ENDOMETRIAL_SCRATCH = 'Endometrial Scratch',
  HYSTEROSCOPY = 'Hysteroscopy',
  HISTOLOGY = 'Histology (small sample)',
  MOCK_EMBRYO_TRANSFER = 'Mock Embryo Transfer',
  EMBRYO_GLUE = 'Embryo Glue'
}

export enum GeneticOptions {
  PGT_A = 'PGT-A',
  PGT_A_PLUS = 'PGT-A+',
  PGT_M = 'PGT-M',
  PGT_SR = 'PGT-SR',
  PGT_P = 'PGT-P'
}

export interface BabiesInfo {
  height: number;
  weight: number;
  sex: PersonSex;
}

export interface Cycle
  extends Omit<
    CycleServer,
    | 'startDate'
    | 'surrogacy'
    | 'dueDate'
    | 'dateOfBirth'
    | 'appointments'
    | 'prescriptions'
    | 'prescriptionsRx'
    | 'outcomeDate'
    | 'cycleDayOne'
  > {
  startDate?: Date;
  surrogacy?: YesOrNo;
  dueDate?: Date;
  dateOfBirth?: Date;
  cycleDayOne?: Date;
  appointments?: Appointment[];
  medicationProtocols?: Prescription[];
  prescriptionsRx?: PrescriptionsRx[];
  outcomeDate?: Date;
  isHistorical?: boolean;
  firstBetaValue?: number;
  firstBetaUnit?: string;
  secondBetaValue?: number;
  secondBetaUnit?: string;
  medicationsToDelete?: string[];
}

export interface GameteReport {
  cryo?: number;
  transfered?: number;
  discarded?: number;
  euploid?: number;
  aneuploid?: number;
  mosiac?: number;
  eggs?: number;
  sperm?: number;
}

export interface CycleOutcomeDetails {
  patientId: string;
  cycleId?: string;
  patientName: string;
  status: CycleStatus;
  outcome: CycleOutcome;
  pregnancyStatus?: PregnancyStatus;
  dueDate?: Date;
  dateOfBirth?: Date;
  numberOfBabies?: number;
  babiesInfo?: BabiesInfo[];
  outcomeDate?: Date;
  firstBetaValue?: number;
  firstBetaUnit?: string;
  secondBetaValue?: number;
  secondBetaUnit?: string;
}

export interface NewCycleForm {
  id?: string;
  patientId: string;
  treatmentType: TreatmentTypes;
  startDate: Date;
  location: CycleLocation;
  eggSource: EggSource;
  spermSource: SpermSource;
  treatmentProtocol: ProtocolTypes;
  treatmentProtocolTemplate: ProtocolTemplates;
  surrogacy: YesOrNo;
  eggOptions: EggOptions[];
  spermOptions: SpermOptions[];
  uterusOptions: UterusOptions[];
  geneticOptions: GeneticOptions[];
  additionalNotes?: string;
  status?: CycleStatus;
  isHistorical?: boolean;
}

export interface CycleScheduleForm {
  patientName: string;
  cycleDayOne: Date;
  appointments: Appointment[];
}

interface NewCycleDocuments {
  prescriptionsDocumentFile?: File;
  prescriptionsDocumentData?: {
    templateId: number;
    patientId: string;
    annotations?: string;
    metadata?: string;
    isCompleted?: string;
  };
  ordersDocumentFile?: File;
  ordersDocumentData?: {
    templateId: number;
    patientId: string;
    annotations?: string;
    metadata?: string;
    isCompleted?: string;
  };
}

interface NewCycleOutcome {
  dateOfBirth: Date;
  dueDate: Date;
  outcomeDate: Date;
}

export type NewCycleFormValues = Partial<
  NewCycleForm &
    CycleScheduleForm &
    MedicalProtocolForm &
    PrescriptionRxForm &
    NewCycleDocuments &
    NewCycleOutcome
>;

export enum ProtocolPhases {
  DOWN_REGULATION = 'downRegulation',
  STIMULATION = 'stimulation',
  AGONIST = 'agonist',
  TRIGGER = 'trigger',
  LUTEAL_SUPPORT = 'Luteal Support',
  TREATMENT_PREP = 'Treatment Prep',
  PRIMING = 'Priming',
  MAINTENANCE = 'Maintenance',
  ANTAGONIST = 'Antagonist',
  'N/A' = 'N/A'
}

export enum CyclePhases {
  DOWN_REGULATION = 'downRegulation',
  STIMULATION = 'stimulation',
  AGONIST = 'agonist',
  TRIGGER = 'trigger',
  LUTEAL_SUPPORT = 'Luteal Support',
  EGGS_COLLECTED = 'eggsCollection',
  EMBRYO_TRANSFER = 'embryoTransfer',
  OUTCOME_POSITIVE = 'outcome-positive',
  OUTCOME_NEGATIVE = 'outcome-negative',
  IUI = 'iui',
  THAW = 'thaw'
}

export interface CycleDataLabResult {
  value: number;
  labResultId: string;
  measurementType: string;
}

export interface CycleTreatmentWitness {
  id: string;
  date: Date;
  embryologist: StaffMember;
  witness: StaffMember;
  task: PatientTask;
  consumables?: string[];
}

type CycleDataMedications = Record<
  string,
  {
    dosage: number;
    id: string;
  }
>;

export interface CycleData {
  date: string;
  Progesterone?: CycleDataLabResult;
  LH?: CycleDataLabResult;
  Estradiol?: CycleDataLabResult;
  FSH?: CycleDataLabResult;
  endometrium?: number;
  embryosCount?: number;
  embryosPercentage: number;
  folliclesSizeLeft?: number[];
  folliclesSizeRight?: number[];
  afcLeft?: number;
  afcRight?: number;
  follicularExamId?: string;
  witnessedTasks?: CycleTreatmentWitness[];
  notes?: CycleNote[];
  medications?: CycleDataMedications;
  appointments?: Appointment[];
}

export enum CycleWizardVariant {
  EDIT_CYCLE_INFO = 'EDIT_CYCLE_INFO',
  ACTIVATE_CYCLE = 'ACTIVATE_CYCLE',
  INTENDED_CYCLE = 'INTENDED_CYCLE',
  PREVIOUS_CYCLE = 'PREVIOUS_CYCLE',
  ADD_PRESCRIPTION = 'ADD_PRESCRIPTION'
}

export interface CycleNotesToCreate {
  cycleId: string;
  date: string;
  patientId: string;
  notesToCreate: string[];
  notesToDelete?: string[];
}

export interface INote {
  id?: string;
  value: string;
  date?: Date;
  createdAt?: Date;
  createdByStaffId?: string;
}

export interface CycleNote extends INote {
  cycleId?: string;
  patientId?: string;
}

export enum IrregularMenstrual {
  IRREGULAR_CYCLE = 'Irregular Cycle'
}

export enum Gamete {
  EMBRYO = 'EMBRYO',
  EGG = 'EGG',
  SEMEN = 'SEMEN'
}
export interface ThawReportForm {
  id?: string;
  patientId: string;
  cycleId: string;
  patientName: string;
  dateOfThaw: Date;
  gameteType: Gamete;
  thawed: number;
  gameteIds: string[];
  straw: number;
}

export interface GameteThawReport {
  embryosThawed: number;
  euploid: number;
  cryo: number;
  gametes: GameteThawed[];
}

export interface SemenVial {
  id: string;
  patientId: string;
  cycleId?: string;
  semenAnalysisId: string;
  ploidy?: GeneticStatus;
  fate?: Fate;
}

export enum CycleViews {
  STANDARD_VIEW = 'Standard view',
  LAB_VIEW = 'Lab view'
}
export interface GameteThawed {
  id: string;
  patientId: string;
  cycleId?: string;
  eggAndEmbryoId?: string;
  semenVialId?: string;
  gameteType: Gamete;
  gradeAtFreeze?: string;
  gradeAtThaw?: string;
  gradeAtTransfer?: string;
  assistedHatching?: boolean;
  biopsy?: boolean;
  biopsyResults?: string;
  geneticStatus?: GeneticStatus;
  fate?: Fate;
}

export interface GraphDataPhase {
  name: CyclePhases;
  startDateIndex: number;
  endDateIndex?: number;
  eggsCollected?: number;
  embryosTransferred?: number;
  transferExamId?: string;
  semenAnalysisId?: string;
  eggRetrievalId?: string;
  iuiExamId?: string;
}

export interface CycleGraphData {
  data: CycleData[];
  medicationsList: {
    medicationName: string;
    unit: string;
    id: string;
  }[];
  cyclePhases: GraphDataPhase[];
}

export interface AppointmentWithOrderDocumentPayload
  extends Appointment,
    Omit<CreatePatientDocumentPayload, 'patientId' | 'file' | 'templateId'> {
  templateKey: string;
}

export interface UpdateCyclePayload {
  cycle: Partial<Cycle>;
  appointmentsWithOrderDocumentPayload?: AppointmentWithOrderDocumentPayload[];
  prescriptionsDocumentPayload?: CreatePatientDocumentPayload;
}

export enum CycleWizardStepTypes {
  EDIT_CYCLE_INFO = 'EDIT_CYCLE_INFO',
  MEDICATION_PROTOCOL = 'MEDICATION_PROTOCOL',
  CONFIRM_PRESCRIPTION = 'CONFIRM_PRESCRIPTION',
  CYCLE_SCHEDULE = 'CYCLE_SCHEDULE',
  CYCLE_OUTCOME = 'CYCLE_OUTCOME',
  SIGN_DOCUMENTS = 'SIGN_DOCUMENTS'
}

export interface CycleWizardStep {
  type: CycleWizardStepTypes;
  header: string;
  fullWidth?: boolean;
  maxWidth?: Breakpoint | false;
}
