export const getImageAsBase64FromURL = async (url: string) => {
  // Load the PNG image
  const response = await fetch(url);
  const imageBlob = await response.blob();
  // Convert the Blob to a Base64 string
  const base64data = await new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    reader.onloadend = () => resolve(reader.result as string);
  });
  return base64data;
};
