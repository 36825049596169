import axiosApiInstance from 'src/utils/axios';
import {
  convertDocumentFileServerToClient,
  convertPatientDocumentServerToClient
} from '../modules/documents/conversions';
import {
  CreatePatientDocumentPayload,
  CreatePatientDocumentPayloadServer,
  Document,
  DocumentTypes,
  EditDocumentPayload,
  PatientDocument,
  PatientDocumentServer,
  TemplateFile
} from 'src/types/documents';
import { PatientDiagnosisServer } from 'src/types/patient-server';
import dayjs from 'dayjs';
import { longDateTimeMsFormat } from 'src/utils/dateAndTIme';

export async function getDocumentTemplatesRequest(): Promise<Document[]> {
  const response = await axiosApiInstance.get<Document[]>('/templates');

  return response.data.map((template) =>
    convertDocumentFileServerToClient(template)
  );
}

export async function createDocumentTemplateRequest(
  templateFile: TemplateFile
): Promise<TemplateFile> {
  const formData = new FormData();

  formData.append('file', templateFile.file);
  formData.append('name', templateFile.name);
  formData.append('type', templateFile.type);

  const response = await axiosApiInstance.post<TemplateFile>(
    '/templates',
    formData
  );

  return response.data;
}

export async function createPatientDocumentRequest(
  documentFile: CreatePatientDocumentPayload
): Promise<CreatePatientDocumentPayloadServer> {
  const formData = new FormData();

  formData.append('file', documentFile.file);
  if (documentFile.templateId) {
    formData.append('templateId', documentFile.templateId);
  }
  formData.append('patientId', documentFile.patientId);
  formData.append('annotations', documentFile?.annotations || '');
  formData.append('documentType', documentFile?.documentType || '');
  formData.append('metadata', documentFile?.metadata || '');
  formData.append(
    'name',
    documentFile?.file?.name ||
      `Document ${dayjs().format(longDateTimeMsFormat)}`
  );
  formData.append('isCompleted', String(documentFile?.isCompleted || false));

  const response =
    await axiosApiInstance.post<CreatePatientDocumentPayloadServer>(
      `/documents/${documentFile.patientId}`,
      formData
    );

  return response.data;
}

export async function deleteDocumentRequest(
  documentId: string,
  patientId: string
): Promise<void> {
  await axiosApiInstance.delete(
    `/documents/${documentId}/patients/${patientId}`
  );
}

export async function getPatientDocuments(
  patientId: string,
  documentType: DocumentTypes
): Promise<PatientDocument[]> {
  const params = new URLSearchParams({
    documentType
  });

  const response = await axiosApiInstance.get<PatientDiagnosisServer[]>(
    `/documents/patient/${patientId}/staff?${params.toString()}`
  );

  return response.data.map((template) =>
    convertPatientDocumentServerToClient(template)
  );
}

export async function getDocumentByIdRequest(
  documentId: string
): Promise<PatientDocument> {
  const response = await axiosApiInstance.get<Document>(
    `/documents/${documentId}`
  );

  return convertPatientDocumentServerToClient(response.data);
}

export async function getTemplateByIdRequest(
  templateId: string
): Promise<Document> {
  const response = await axiosApiInstance.get<Document>(
    `/templates/${templateId}`
  );

  return { ...response.data, id: `${response.data?.id}` };
}

export async function updatePatientDocumentRequest(
  documentFile: CreatePatientDocumentPayload,
  documentId: string
): Promise<string> {
  const formData = new FormData();

  formData.append('file', documentFile.file);
  if (/^\d+$/.test(documentFile?.templateId)) {
    formData.append('templateId', documentFile.templateId);
  }
  formData.append('patientId', documentFile.patientId);
  formData.append('annotations', documentFile?.annotations || '');
  formData.append('metadata', documentFile?.metadata || '');
  formData.append('isCompleted', documentFile?.isCompleted ? 'true' : 'false');

  const response = await axiosApiInstance.patch<{ _id: string }>(
    `/documents/${documentId}/sign/patient/${documentFile.patientId}`,
    formData
  );
  const { data } = response;
  return data._id;
}

export async function updateDocumentNameRequest(
  documentFile: EditDocumentPayload,
  documentId: string
): Promise<string> {
  const response = await axiosApiInstance.patch<{ _id: string }>(
    `/documents/${documentId}/patient/${documentFile.patientId}`,
    {
      name: documentFile.fileName,
      documentType: documentFile.documentType
    }
  );
  const { data } = response;
  return data._id;
}

export async function updateTemplateDocumentRequest(
  documentFile: TemplateFile,
  templateId: string
): Promise<string> {
  const formData = new FormData();

  formData.append('file', documentFile.file);
  formData.append('name', documentFile.name);
  formData.append('type', documentFile.type);
  formData.append('defaultAnnotations', documentFile?.defaultAnnotations || '');

  const response = await axiosApiInstance.patch<TemplateFile>(
    `/templates/${templateId}`,
    formData
  );

  return response.data.name;
}

export async function getDocumentByExternalIdRequest(
  labOrderExternalId: string
): Promise<PatientDocument> {
  const response = await axiosApiInstance.get<PatientDocumentServer>(
    `/documents/appointment/${labOrderExternalId}`
  );

  return convertPatientDocumentServerToClient(response.data);
}

export const createPatientDocumentFromTemplateRequest = async ({
  patientId,
  templateId
}: {
  patientId: string;
  templateId: string;
}): Promise<string> => {
  const response = await axiosApiInstance.post(
    `/documents/${patientId}/template/${templateId}`
  );

  return response.data;
};
