import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputField from 'src/components/data-entry/InputField';
import Select from 'src/components/data-entry/Select';
import Button from 'src/components/display/Button';
import Loader from 'src/components/display/Loader';
import Box from 'src/components/layout/Box';
import Center from 'src/components/layout/Center';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { useDialog } from 'src/contexts/UIContexts';
import useDocumentsApi from 'src/hooks/useDocumentsApi';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { DocumentTypes, EditDocumentFormValues } from 'src/types/documents';
import { getFullName } from 'src/utils/general';

interface EditDocumentDialogProps {
  documentType: DocumentTypes;
  patientId: string;
  originalFileName: string;
  documentId: string;
}

const EditDocumentDialog = ({
  documentType,
  patientId,
  originalFileName,
  documentId
}: EditDocumentDialogProps) => {
  const { getPatientById } = usePatientsApi();
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const fileNameWithoutExtension = originalFileName.replace(/\.[^/.]+$/, '');

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const defaultValues: EditDocumentFormValues = {
    documentType: documentType,
    patientName: getFullName(patient),
    templateName: '',
    file: null,
    fileName: fileNameWithoutExtension
  };

  const { updateDocumentName } = useDocumentsApi();
  const { mutate: updateDocumentNameMutate } = updateDocumentName();

  const { control, formState, handleSubmit, setValue } =
    useForm<EditDocumentFormValues>({
      mode: 'onChange',
      defaultValues
    });

  const { errors } = formState;

  useEffect(() => {
    if (!patient) return;
    setValue('patientName', getFullName(patient.personalInfo));
  }, [patient]);

  const onSubmit = async ({
    fileName,
    documentType
  }: EditDocumentFormValues) => {
    const extension = originalFileName.split('.').pop();
    const fileNameWithExtension = `${fileName}.${extension}`;
    updateDocumentNameMutate({
      documentId,
      document: {
        patientId,
        fileName: fileNameWithExtension,
        documentType: documentType
      }
    });
    closeDialog();
  };

  if (isLoadingPatient) return <Loader />;

  return (
    <Box marginTop={spacings.large} gap={spacings.large} minWidth={400}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection="column" gap={spacings.xlarge}>
          <Box flex={1}>
            <Controller
              name="patientName"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  disabled={true}
                  inputRef={ref}
                  label={t('PATIENT_NAME')}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="documentType"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('CHOOSE_DOCUMENT_TYPE')}
                  inputRef={ref}
                  error={!!errors?.documentType}
                  helperText={errors?.documentType?.message}
                  defaultOption={t('DOCUMENT_TYPE')}
                  options={Object.entries(DocumentTypes).map(
                    ([key, value]) => ({
                      label: t(key),
                      value: value
                    })
                  )}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="fileName"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  label={t('EDIT_DOCUMENT_NAME')}
                  inputRef={ref}
                  error={!!errors?.documentType}
                  helperText={errors?.documentType?.message}
                  placeholder={originalFileName}
                  fullWidth
                />
              )}
            />
          </Box>
        </Flex>

        <Center gap={spacings.xlarge} marginTop={spacings.medium}>
          <Button type="submit">{t('CONFIRM')}</Button>
        </Center>
      </form>
    </Box>
  );
};

export default EditDocumentDialog;
