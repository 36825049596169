import { FC, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WebViewer from '@pdftron/webviewer';
import { ToastType } from 'src/components/display/Toast/Toast';
import Loader from 'src/components/display/Loader';
import Center from 'src/components/layout/Center';
import Select from 'src/components/data-entry/Select';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import Box from 'src/components/layout/Box';
import useDocumentsApi from '../../hooks/useDocumentsApi';
import { getWebViewerSaveTemplateAction } from './utils/editorUtils/getWebViewerSaveTemplateAction';
import { YourDocumentIsSavingLoader } from './YourDocumentIsSavingLoader';
import { useToast, useDialog } from 'src/contexts/UIContexts';

export const TemplateEditor: FC = () => {
  const viewer = useRef(null);
  const { openToast } = useToast();
  const { t } = useTranslation();

  const { updateTemplateDocument, getDocumentsTemplates } = useDocumentsApi();

  const { data: templates, isLoading: isLoadingTemplates } =
    getDocumentsTemplates();

  const { mutate: updateTemplateDocumentMutate } = updateTemplateDocument();

  const { openDialog, closeDialog } = useDialog();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTemplateId = searchParams.get('templateId');

  const chosenTemplate = templates?.find(
    (template) => template.id === currentTemplateId
  );

  const extension = 'docx';

  const onSelectTemplate = useCallback(
    (ev) => {
      const templateToSet = templates?.find(
        (template) => template.id === ev.target.value
      );

      if (templateToSet) {
        searchParams.set('templateId', templateToSet.id);
        setSearchParams(searchParams);
        window.location.reload();
      }
    },
    [templates, searchParams, setSearchParams]
  );

  useEffect(() => {
    if (isLoadingTemplates || !currentTemplateId) return;
    if (!chosenTemplate?.url || !chosenTemplate?.name) {
      openToast({
        title: !chosenTemplate?.url
          ? t('TEMPLATE_NOT_FOUND')
          : t('TEMPLATE_MISSING_NAME'),
        children: !chosenTemplate?.url ? t('TRY_AGAIN_LATER') : undefined,
        type: ToastType.ERROR
      });
      return;
    }

    WebViewer(
      {
        path: '/apryse',
        licenseKey: process.env.REACT_APP_APRYSE_KEY
      },
      viewer.current
    ).then(async (instance) => {
      const { documentViewer, annotationManager } = instance.Core;

      instance.UI.loadDocument(chosenTemplate?.url, {
        extension
      });

      annotationManager.addEventListener(
        'annotationChanged',
        async (_annotations, _action) => {
          await annotationManager.exportAnnotations();

          if (_action === 'delete') {
            annotationManager.deleteAnnotations(_annotations);
          }
        }
      );

      documentViewer.setDocumentXFDFRetriever(async () => {
        return chosenTemplate?.defaultAnnotations || '';
      });

      const templateIsBeingSavedLoader = () =>
        openDialog({
          children: <YourDocumentIsSavingLoader />,
          maxWidth: 'xl'
        });

      documentViewer.addEventListener('documentLoaded', async () => {
        instance.UI.setHeaderItems((header) => {
          return getWebViewerSaveTemplateAction({
            header,
            webViewerInstance: instance,
            action: updateTemplateDocumentMutate,
            chosenTemplate,
            templateIsBeingSavedLoader,
            closeDialog
          });
        });
      });
    });
  }, [isLoadingTemplates, chosenTemplate]);

  if (isLoadingTemplates) {
    return (
      <Center height="85vh">
        <Loader />
      </Center>
    );
  }

  return (
    <Flex className="MyComponent" flexDirection="column" gap={spacings.large}>
      <Typography variant="h1">{t('CHOOSE_A_TEMPLATE_TO_EDIT')}</Typography>
      <Box width="40%">
        <Select
          value={chosenTemplate?.id || ''}
          onChange={onSelectTemplate}
          options={
            templates?.map((template) => ({
              label: template.name,
              value: template.id
            })) || []
          }
        />
      </Box>

      <div className="webviewer" ref={viewer} style={{ height: '85vh' }} />
    </Flex>
  );
};
