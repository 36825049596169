import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import InputField from 'src/components/data-entry/InputField/InputField';
import Button from 'src/components/display/Button/Button';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import TextArea from 'src/components/data-entry/TextArea';
import Select from 'src/components/data-entry/Select/Select';
import Loader from 'src/components/display/Loader/Loader';
import { ExamReason, ExamReport, OtherExaminationTypes } from 'src/types/exam';
import { YesOrNo } from 'src/types/global';
import { getFullName } from 'src/utils/general';
import useExams from 'src/hooks/useExams';
import { DoctorChips } from '../common/DoctorChips';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { Icd10Chips } from '../common/Icd10Chips';

export const AddEditEggRetrievalReportForm: FC<{
  patientId: string;
  cycleId?: string;
  examId?: string;
}> = ({ patientId, cycleId, examId }) => {
  const isEdit = !!examId;
  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();
  const { createExamReport, updateExamReport, getExaminationById } = useExams();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: examination, isLoading: isLoadingExam } = getExaminationById(
    examId,
    {
      enabled: !!examId
    }
  );

  const { mutate: createReport, isLoading: isCreatingReport } =
    createExamReport();
  const { mutate: updateReport, isLoading: isUpdatingReport } =
    updateExamReport();

  const defaultValues: ExamReport = {
    id: examination?.id,
    patientId: examination?.patientId || patientId,
    cycleId: examination?.cycleId || cycleId,
    patientName: '',
    dateOfExamination: dayjs(examination?.examDate).toDate(),
    swabCountCorrect: examination?.correctSwabCount || YesOrNo.NO,
    uncomplicatedProcedure: examination?.procedureUncompleted || YesOrNo.NO,
    requestingPhysicianId: examination?.requestedPhysician || '',
    performedById: examination?.performedBy || '',
    signedOffById: examination?.signedOffBy || '',
    type: OtherExaminationTypes.EGG_RETRIEVAL,
    reason: examination?.reason || ExamReason.IN_CYCLE,
    notes: examination?.notes || '',
    icd10Codes: examination?.icd10Codes || []
  };

  const { control, formState, handleSubmit, reset } = useForm<ExamReport>({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (!examination && !patient) return;
    reset({
      id: examination?.id,
      patientId: examination?.patientId || patientId,
      cycleId: examination?.cycleId || cycleId,
      patientName: getFullName(patient.personalInfo),
      dateOfExamination: dayjs(examination?.examDate).toDate(),
      swabCountCorrect: examination?.correctSwabCount || YesOrNo.NO,
      uncomplicatedProcedure: examination?.procedureUncompleted || YesOrNo.NO,
      requestingPhysicianId: examination?.requestedPhysician || '',
      performedById: examination?.performedBy || '',
      signedOffById: examination?.signedOffBy || '',
      type: OtherExaminationTypes.EGG_RETRIEVAL,
      reason: examination?.reason || ExamReason.IN_CYCLE,
      notes: examination?.notes || '',
      icd10Codes: examination?.icd10Codes || []
    });
  }, [examination, patient]);

  const { errors } = formState;

  const onSubmit = async (report: ExamReport) => {
    if (isEdit) {
      updateReport(report);
    } else {
      await createReport(report);
    }
  };

  if (isLoadingPatient || isLoadingExam) return <Loader />;

  return (
    <form noValidate>
      <Flex gap={spacings.large} marginTop={spacings.large}>
        <Box flex={1}>
          <Box marginBottom={spacings.large}>
            <Controller
              name="patientName"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  disabled
                  label={t('PATIENT_NAME')}
                  placeholder={t('PATIENT_NAME')}
                  error={!!errors.patientName}
                  helperText={errors?.patientName?.message}
                  required
                  fullWidth
                />
              )}
            />
          </Box>
          <Box marginBottom={spacings.large}>
            <Controller
              name="dateOfExamination"
              control={control}
              rules={{
                required: t('DATE_REQUIRED'),
                validate: (value) => {
                  const date = dayjs(value);
                  if (date.isAfter(dayjs())) {
                    return t('DATE_CANT_BE_IN_THE_FUTURE');
                  }
                  return true;
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  label={t('DATE_OF_PROCEDURE')}
                  inputRef={ref}
                  error={!!errors?.dateOfExamination}
                  helperText={errors.dateOfExamination?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Flex gap={spacings.large} marginBottom={spacings.large}>
            <Controller
              name="swabCountCorrect"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('SWAB_COUNT_CORRECT')}
                  inputRef={ref}
                  error={!!errors?.swabCountCorrect}
                  helperText={errors?.swabCountCorrect?.message}
                  defaultOption={t('SWAB_COUNT_CORRECT')}
                  options={Object.keys(YesOrNo).map((option) => ({
                    label: t(option),
                    value: option
                  }))}
                />
              )}
            />
            <Controller
              name="uncomplicatedProcedure"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('UNCOMPLICATED_PROCEDURE')}
                  inputRef={ref}
                  error={!!errors?.uncomplicatedProcedure}
                  helperText={errors?.uncomplicatedProcedure?.message}
                  defaultOption={t('UNCOMPLICATED_PROCEDURE')}
                  options={Object.keys(YesOrNo).map((option) => ({
                    label: t(option),
                    value: option
                  }))}
                />
              )}
            />
          </Flex>
        </Box>
        <Box flex={1}>
          <Flex gap={spacings.large} marginBottom={spacings.medium}>
            <Box flex={1} marginBottom={spacings.large}>
              <Controller
                name="requestingPhysicianId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-egg-retrieval-report-requesting-physician"
                    showSelectedValue
                    label={t('REQUESTING_PROVIDER')}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="performedById"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-egg-retrieval-report-performed-by"
                    showSelectedValue
                    label={t('PERFORMED_BY')}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="signedOffById"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-egg-retrieval-report-signed-off-by"
                    showSelectedValue
                    label={t('SIGNED_OFF_BY')}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
          </Flex>
          <Box flex={1} marginBottom={spacings.large}>
            <Icd10Chips
              control={control}
              name="icd10Codes"
              error={!!errors.icd10Codes}
              helperText={errors?.icd10Codes?.message}
              label={t('ICD_10_CODES_OR_OBSERVATIONS').toUpperCase()}
              flattenValue
            />
          </Box>
          <Box flex={1} marginBottom={spacings.large}>
            <Controller
              name="notes"
              control={control}
              render={({ field: { ...field } }) => (
                <TextArea
                  {...field}
                  label={t('PROCEDURE_NOTES').toUpperCase()}
                  placeholder={t('ENTER_COMMENT')}
                  error={!!errors?.notes}
                  helperText={errors?.notes?.message}
                  required
                  fullWidth
                  minRows={6}
                />
              )}
            />
          </Box>
        </Box>
      </Flex>
      <Box width="20%">
        <Button
          fullWidth
          disabled={isCreatingReport || isUpdatingReport}
          onClick={handleSubmit(onSubmit)}
          type="button"
        >
          {t('SUBMIT')}
        </Button>
      </Box>
    </form>
  );
};
