import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StopIcon from '@mui/icons-material/Stop';

import Flex from 'src/components/layout/Flex/Flex';
import { iconSizes } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import IconButton from 'src/components/display/IconButton';
import { TooltipWrapper } from 'src/components/display/Tooltip';
import Loader from 'src/components/display/Loader';
import Typography from 'src/components/display/Typography';
import usePrescription from 'src/hooks/usePrescription';
import { UpdatePrescriptionDosage } from 'src/types/prescription';
import { CycleStatus } from 'src/types/cycle';
import { usePopover, useDialog } from 'src/contexts/UIContexts';
import {
  ConfirmDeletePrescription,
  checkIsMedicationStoppingCompletely
} from './TreatmentEditMedicationForm';

interface EditMedicationPopoverHeaderProps {
  prescriptionId: string;
  title: string;
  patientId: string;
  cycleId: string;
  date: string;
}

export const EditMedicationPopoverHeader: FC<
  EditMedicationPopoverHeaderProps
> = ({ prescriptionId, title, patientId, cycleId, date }) => {
  const { t } = useTranslation();
  const { closeAllPopovers } = usePopover();
  const { openDialog, closeDialog } = useDialog();
  const { getPrescriptionById, updatePrescriptionDosage } = usePrescription();

  const {
    data: prescription,
    isLoading: isLoadingPrescription,
    isFetching: isFetchingPrescription
  } = getPrescriptionById(prescriptionId);

  const {
    mutate: handleUpdatePrescriptionDosage,
    isLoading: isUpdatingPrescription
  } = updatePrescriptionDosage();

  const isLoading =
    isUpdatingPrescription || isLoadingPrescription || isFetchingPrescription;

  const stopPrescription = async (): Promise<void> => {
    const details: UpdatePrescriptionDosage = {
      prescriptionId,
      dosage: 0,
      patientId,
      cycleId,
      startDate: new Date(date)
    };

    const isPrescriptionStoppingCompletely =
      checkIsMedicationStoppingCompletely(details, prescription);

    if (isPrescriptionStoppingCompletely) {
      openDialog({
        header: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
        children: (
          <ConfirmDeletePrescription
            onConfirm={async () => {
              await handleUpdatePrescriptionDosage(details, {
                onSuccess: () => {
                  closeAllPopovers();
                  closeDialog();
                }
              });
            }}
          />
        )
      });

      return;
    }

    await handleUpdatePrescriptionDosage(details, {
      onSuccess: () => closeAllPopovers()
    });
  };

  const hideStopButton =
    prescription?.cycle?.isHistorical ||
    prescription?.cycle?.status === CycleStatus.PENDING;

  return (
    <Flex justifyContent="space-between" width="100%">
      <Typography fontWeight={fontWeights.extraBold}>{title}</Typography>
      {!hideStopButton && (
        <IconButton
          disabled={isLoading}
          icon={
            isLoading ? (
              <Loader size={iconSizes.xsmall} />
            ) : (
              <TooltipWrapper title={t('STOP_MEDICATION')}>
                <StopIcon sx={{ color: Colors.mineShaft }} />
              </TooltipWrapper>
            )
          }
          onClick={stopPrescription}
          bgColor={isLoading ? 'transparent' : 'alto'}
          iconSize="xsmall"
        />
      )}
    </Flex>
  );
};
