import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import IconButton from 'src/components/display/IconButton';
import { TooltipWrapper } from 'src/components/display/Tooltip';
import { Colors } from 'src/components/styles/colors';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar-empty.svg';
import { Appointment } from 'src/types/appointment';
import AddEditAppointment from 'src/modules/calendar/AddEditAppointment';
import { useDialog } from 'src/contexts/UIContexts';

export const CycleDayAppointments: FC<{
  appointments?: Appointment[];
  cycleId?: string;
  date: string;
}> = ({ appointments, cycleId, date }) => {
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const { patientId } = useParams();

  const handleOpenAddAppointment = useCallback(() => {
    if (!appointments.length) {
      openDialog({
        header: t('ADD_APPOINTMENT'),
        children: (
          <AddEditAppointment
            patientId={patientId}
            cycleId={cycleId}
            defaultDate={date}
          />
        ),
        fullWidth: true,
        maxWidth: 'lg',
        closeButtonId: 'close-add-appointment-dialog'
      });
    }
  }, [openDialog, patientId]);

  return (
    <TooltipWrapper
      title={
        appointments.length ? t('SCHEDULED_APPOINTMENT') : t('ADD_APPOINTMENT')
      }
      arrow
      placement="bottom"
    >
      <Box>
        <IconButton
          iconSize="xsmall"
          icon={
            appointments.length ? (
              <CalendarIcon height={12} width={12} />
            ) : (
              <AddIcon style={{ color: Colors.black }} />
            )
          }
          bgColor={`${appointments.length ? 'mineShaft' : 'alto2'}`}
          onClick={() => handleOpenAddAppointment()}
        />
      </Box>
    </TooltipWrapper>
  );
};
