import { FC, useEffect, useRef, useState } from 'react';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import {
  DocumentTypes,
  orderTemplateId,
  CreatePatientDocumentPayload
} from 'src/types/documents';
import useMeApi from 'src/hooks/useMeApi';
import useDocumentsApi from '../../hooks/useDocumentsApi';
import useGetPatientDocumentData from '../../hooks/useGetPatientDocumentData';
import { VerifyDocumentSignee } from './VerifyDocumentSignee';
import { YourDocumentIsSavingLoader } from './YourDocumentIsSavingLoader';
import { initNewDocumentInEditor } from './utils/editorUtils/webViewerFunctions';
import { zIndices } from 'src/components/styles/constants';
import { getDocumentAsPdfFileFromEditor } from './utils/editorUtils/getDocumentAsPdfFileFromEditor';
import {
  useAuth,
  useDocumentEditor
} from 'src/contexts/AppContexts/AppContexts';
import { useDialog } from 'src/contexts/UIContexts';
import { getImageAsBase64FromURL } from './utils/getImageAsBase64FromURL';
import { extractFileNameWithoutExtension } from './utils/editorUtils/extractFileNameWithoutExtension';

export const DocumentEditor: FC = () => {
  const { isAuthenticated } = useAuth();

  const viewer = useRef(null);
  const [instance, setInstance] = useState<WebViewerInstance>(null);

  const { openDialog, closeDialog } = useDialog();
  const { onDocumentsModalOpen, documentEditorParams, isDocumentsModalOpen } =
    useDocumentEditor();
  const { createPatientDocument, updatePatientDocument } = useDocumentsApi();

  const { getMe } = useMeApi();

  const {
    cycleId,
    documentId,
    labOrdersId,
    orderId: orderIdProp,
    patientId,
    templateId
  } = documentEditorParams;

  const orderId = orderIdProp?.split(',')?.[0] || null;

  const { data: loggedStaffMember, isLoading: isLoadingStaffMember } = getMe({
    enabled: isAuthenticated
  });

  const { mutate: createPatientDocumentMutate } = createPatientDocument();

  const { mutate: updatePatientDocumentMutate } = updatePatientDocument();

  const {
    templateData,
    documentToEdit,
    templates,
    isLoading: isLoadingPatientDocumentData
  } = useGetPatientDocumentData({
    patientId,
    cycleId,
    templateId,
    documentId,
    labOrdersId,
    orderId,
    enabled: isAuthenticated
  });

  const isLoading = isLoadingPatientDocumentData || isLoadingStaffMember;
  const chosenTemplate = templates?.find(({ id }) => id === templateId);
  const isClinicDocument = !Object.keys(documentToEdit || {})?.length;

  const stringifiedTemplateData = JSON.stringify(templateData);

  const documentToEditRef = useRef(documentToEdit);

  useEffect(() => {
    documentToEditRef.current = documentToEdit;
  }, [documentToEdit]);

  const documentMetadata = !documentId
    ? stringifiedTemplateData
    : documentToEdit?.metadata || '';

  const documentIsBeingSavedLoader = () =>
    openDialog({
      children: <YourDocumentIsSavingLoader />,
      maxWidth: 'xl',
      sx: { zIndex: zIndices.highest }
    });

  useEffect(() => {
    const initializeViewer = async () => {
      if (!viewer.current) return;
      const instanceProp = await WebViewer(
        {
          path: '/apryse',
          licenseKey: process.env.REACT_APP_APRYSE_KEY
        },
        viewer.current
      );

      if (!instance) {
        setInstance(instanceProp);
      }
    };

    initializeViewer();
  }, [viewer]);

  const eventListenersAttached = useRef(new Set());

  // Open edited documents
  useEffect(() => {
    if (
      !instance ||
      isLoading ||
      !documentEditorParams ||
      !isDocumentsModalOpen
    ) {
      return;
    }

    const { ToolbarGroup, closeElements, setToolbarGroup, openElements } =
      instance.UI;

    const init = async () => {
      await initNewDocumentInEditor({
        openDialog,
        handleToolbarGroupChangeAuthChildren: (
          <VerifyDocumentSignee
            openElements={openElements}
            closeElements={closeElements}
            setToolbarGroup={setToolbarGroup}
            ToolbarGroup={ToolbarGroup}
          />
        ),
        isClinicDocument,
        instance,
        chosenTemplate,
        documentToEdit,
        orderTemplateId,
        patientId,
        documentId,
        closeDialog,
        createDocument: createPatientDocumentMutate,
        documentIsBeingSavedLoader,
        documentMetadata,
        loggedStaffMember,
        templateId,
        updateDocument: updatePatientDocumentMutate
      });
    };

    init();

    if (documentToEdit?.annotations) {
      instance.Core.documentViewer.setDocumentXFDFRetriever(() => {
        return new Promise((resolve) => {
          resolve(documentToEdit.annotations);
        });
      });
    }
    const { isForceSave } = documentEditorParams;
    const { documentViewer } = instance.Core;

    // Disable editing features if it's a CONSENT document
    toggleEditingFeatures(
      instance,
      documentToEdit?.documentType === DocumentTypes.CONSENT,
      isForceSave
    );

    // Check if the listener is already attached using the Set
    if (
      isForceSave &&
      documentToEdit.id === documentId &&
      !eventListenersAttached.current.has('annotationsLoaded')
    ) {
      documentViewer.addEventListener(
        'annotationsLoaded',
        handleAnnotationsLoadedEvent,
        { once: true }
      );
      eventListenersAttached.current.add('annotationsLoaded');
    }
  }, [documentToEdit, isLoading, isDocumentsModalOpen]);

  // Open templates
  useEffect(() => {
    if (
      !instance ||
      isLoading ||
      documentId ||
      !isDocumentsModalOpen ||
      !chosenTemplate
    ) {
      return;
    }

    const { ToolbarGroup, closeElements, setToolbarGroup, openElements } =
      instance.UI;

    const init = async () => {
      await initNewDocumentInEditor({
        openDialog,
        handleToolbarGroupChangeAuthChildren: (
          <VerifyDocumentSignee
            openElements={openElements}
            closeElements={closeElements}
            setToolbarGroup={setToolbarGroup}
            ToolbarGroup={ToolbarGroup}
          />
        ),
        isClinicDocument: true,
        instance,
        chosenTemplate,
        documentToEdit: {},
        orderTemplateId: null,
        patientId,
        documentId: null,
        closeDialog,
        createDocument: createPatientDocumentMutate,
        documentIsBeingSavedLoader,
        documentMetadata,
        loggedStaffMember,
        templateId,
        updateDocument: updatePatientDocumentMutate
      });
    };

    init();
  }, [documentEditorParams, isLoading, isDocumentsModalOpen]);

  const toggleEditingFeatures = async (
    instance: WebViewerInstance,
    isReadOnly: boolean,
    isForceSave: boolean
  ) => {
    const TOOLBAR_GROUP_ANNOTATE = 'toolbarGroup-Annotate';
    const TOOLBAR_GROUP_EDIT = 'toolbarGroup-Edit';
    const TOOLBAR_GROUP_FORMS = 'toolbarGroup-Forms';
    const TOOLBAR_GROUP_FILL_AND_SIGN = 'toolbarGroup-FillAndSign';
    const TOOLBAR_GROUP_INSERT = 'toolbarGroup-Insert';
    const TOOLBAR_GROUP_SHAPES = 'toolbarGroup-Shapes';
    const SAVE_DOCUMENT_BUTTON = 'saveDocumentButton';
    const PAN_TOOL_BUTTON = 'panToolButton';
    const SELECT_TOOL_BUTTON = 'selectToolButton';

    const toolBarElements = [
      TOOLBAR_GROUP_ANNOTATE,
      TOOLBAR_GROUP_EDIT,
      TOOLBAR_GROUP_FILL_AND_SIGN,
      TOOLBAR_GROUP_INSERT,
      TOOLBAR_GROUP_SHAPES,
      PAN_TOOL_BUTTON,
      SELECT_TOOL_BUTTON,
      ...(isForceSave ? [TOOLBAR_GROUP_FORMS, SAVE_DOCUMENT_BUTTON] : [])
    ];

    const {
      documentViewer,
      annotationManager,
      Annotations
    } = instance.Core;

    const handleDocumentLoaded = async () => {
      const currentDocumentToEdit = documentToEditRef.current;
      if (isReadOnly) {
        const annotaionList = annotationManager.getAnnotationsList();
        annotaionList.forEach((annotation) => {
          annotation.ReadOnly = isReadOnly;
        });
      }

      const fieldManager = annotationManager.getFieldManager();
      fieldManager.forEachField((field) => {
        field.flags.set('ReadOnly', isReadOnly);
      });

      if (isReadOnly) {
        // Disable UI elements for editing
        instance.UI.disableElements(toolBarElements);
      } else {
        // Enable UI elements for editing
        instance.UI.enableElements(toolBarElements);
      }

      if (currentDocumentToEdit?.barCodeUrl) {
        // Load barCode if available
        const barCodeUrl = documentToEdit?.barCodeUrl;
        const base64data = await getImageAsBase64FromURL(barCodeUrl);
        const image = new Image();
        image.src = base64data;
        image.onload = async () => {
          const annotationManager = documentViewer.getAnnotationManager();
          const annotationList = annotationManager.getAnnotationsList();

          // Check if the annotation image already exists
          const existingAnnotation = await Promise.all(
            annotationList.map(async (annotation) => {
              if (annotation instanceof Annotations.StampAnnotation) {
                const imageData = await annotation.getImageData();
                return imageData === base64data ? annotation : null;
              }
              return null;
            })
          ).then((results) =>
            results.find((annotation) => annotation !== null)
          );

          if (existingAnnotation) {
            return;
          }

          const annotation = new Annotations.StampAnnotation();

          // Configure annotation properties
          annotation.PageNumber = 1; // Add to the first page
          annotation.Width = image.naturalWidth; // Use the real width of the image
          annotation.Height = image.naturalHeight; // Use the real height of the image
          // Get the page height and set the Y coordinate to place the annotation at the bottom
          const pageHeight = documentViewer.getPageHeight(1);
          const pageWidth = documentViewer.getPageWidth(1);
          annotation.Y = pageHeight - annotation.Height - 20; // 20 is the margin from the bottom
          annotation.X = pageWidth - annotation.Width - 20; // 20 is the margin from the right
          // Set the image data for the annotation
          annotation.setImageData(base64data);

          // Add annotation to the document
          annotationManager.addAnnotation(annotation);
          annotationManager.redrawAnnotation(annotation);
        };
      }
    };

    documentViewer.addEventListener('documentLoaded', async () => {
      handleDocumentLoaded();
    });
  };

  const handleAnnotationsLoadedEvent = async () => {
    const { key } = documentToEdit || {};
    const { documentViewer, annotationManager } = instance.Core;

    let parsedMetadata = {};

    try {
      parsedMetadata = JSON.parse(documentMetadata || '{}');
    } catch (error) {
      throw new Error(`Error parsing document metadata: ${error}`);
    }

    const updatedMetadata = { ...parsedMetadata };
    const templateName = chosenTemplate?.name || '';

    await documentViewer.getDocument().applyTemplateValues(updatedMetadata);

    const { file, xfdfString } = await getDocumentAsPdfFileFromEditor({
      annotationManager,
      documentViewer,
      templateName,
      key,
      originalFileName: documentToEdit?.name
    });

    const fileName = extractFileNameWithoutExtension(documentToEdit?.key);

    const newFileData = new File([file], `${fileName}.pdf`, {
      type: file.type
    });

    const documentPayload: CreatePatientDocumentPayload = {
      file: newFileData,
      patientId,
      templateId,
      annotations: xfdfString,
      isCompleted: false,
      metadata: JSON.stringify(updatedMetadata)
    };

    await updatePatientDocumentMutate(
      {
        document: documentPayload,
        documentId
      },
      {
        onSuccess: () => {
          closeDialog();
          onDocumentsModalOpen(false);
          if (eventListenersAttached.current.has('annotationsLoaded')) {
            documentViewer.removeEventListener(
              'annotationsLoaded',
              handleAnnotationsLoadedEvent
            );
            eventListenersAttached.current.delete('annotationsLoaded');
          }
        }
      }
    );
  };

  return (
    <div className="MyComponent">
      <div className="webviewer" ref={viewer} style={{ height: '85vh' }} />
    </div>
  );
};
